import React, { FunctionComponent } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Button from "react-bootstrap/Button";
import Gamepad from "react-gamepad";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearchPlus,
    faSearchMinus,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowDown,
    faCaretLeft,
    faCaretRight,
    faPlus,
    faMinus,
    faStop,
} from "@fortawesome/free-solid-svg-icons";

import { debounce, bindAll } from "underscore";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";
import { Camera } from "../store/ptz/types";
import { WSAPIResponse } from "@lbcde.org/websocket-api/lib";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const mapState = (state: RootState) => ({
    tally: state.atem.tally,
});

const mapDispatch = () => ({});

const connector = connect(mapState, mapDispatch);

type DPadButtonName = "DPadLeft" | "DPadRight" | "DPadUp" | "DPadDown";
type TriggerButtonName = "LB" | "RB" | "LT" | "RT" | "LS" | "RS";
type ActionButtonName = "A" | "B" | "X" | "Y";
type GamepadButtonName =
    | "Start"
    | "Back"
    | ActionButtonName
    | DPadButtonName
    | TriggerButtonName;

type StickAxisName =
    | "RightStickX"
    | "RightStickY"
    | "LeftStickX"
    | "LeftStickY";
type TriggerAxisName = "LeftTrigger" | "RightTrigger";
type GamepadAxisName = StickAxisName | TriggerAxisName;

interface OwnProps {
    dispatch: Function;
    input: number;
    camera: number;
    cameras: Camera[];
}

type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps;

interface PanTiltControlsState {
    speed: number;
    boost: number;
    axis: {
        direction: string;
        x: number;
        y: number;
    };
    zoom: {
        direction: string;
        y: number;
        speed: number;
    };
    manualFocus: number;
    panSpeed: number;
    tiltSpeed: number;
    buttons: {
        DPadUp: boolean;
        DPadLeft: boolean;
        DPadDown: boolean;
        DPadRight: boolean;
        Y: boolean;
        LB: boolean;
        RB: boolean;
        Start: boolean;
        Back: boolean;
    };
}

type LeftRightButtonRowProps = {
    leftDown?: () => void;
    leftUp?: () => void;
    leftText: string;
    leftIcon?: IconProp | string;

    rightDown?: () => void;
    rightUp?: () => void;
    rightText: string;
    rightIcon?: IconProp | string;
};

const LeftRightButtonRow: FunctionComponent<LeftRightButtonRowProps> = (
    props = {
        leftDown: undefined,
        leftUp: undefined,
        leftText: undefined,
        leftIcon: faCaretLeft,

        rightDown: undefined,
        rightUp: undefined,
        rightText: undefined,
        rightIcon: faCaretRight,
    }
) => (
    <Row className="pb-1">
        <Col
            xs="12"
            className="d-flex flex-row justify-content-between align-items-center"
        >
            <Button
                onMouseDown={props.leftDown}
                onMouseUp={props.leftUp}
                onTouchStart={props.leftDown}
                onTouchEnd={props.leftUp}
                title={props.leftText}
            >
                {typeof props.leftIcon === "string" ? (
                    props.leftIcon
                ) : (
                    <FontAwesomeIcon icon={props.leftIcon} />
                )}
            </Button>
            {props.children}
            <Button
                onMouseDown={props.rightDown}
                onMouseUp={props.rightUp}
                onTouchStart={props.rightDown}
                onTouchEnd={props.rightUp}
                title={props.rightText}
            >
                {typeof props.rightIcon === "string" ? (
                    props.rightIcon
                ) : (
                    <FontAwesomeIcon icon={props.rightIcon} />
                )}
            </Button>
        </Col>
    </Row>
);

class PanTiltZoomControls extends React.Component<
    PropsFromRedux,
    PanTiltControlsState
> {
    constructor(props) {
        super(props);

        const boundFuncs = [
            "handleKeyDown",
            "handleKeyUp",
            "zoomIn",
            "zoomOut",
            "focusNear",
            "focusFar",
            "focusStop",
            "focusModeAuto",
            "focusOnePush",
            "zoomStop",
            "panLeft",
            "panRight",
            "tiltUp",
            "tiltDown",
            "panTiltStop",
            "upLeft",
            "upRight",
            "downLeft",
            "downRight",
            "test",
            "buttonChangeHandler",
            "axisChangeHandler",
        ];

        bindAll(this, ...boundFuncs);
        this.axisPanTilt = debounce(this.axisPanTilt, 50, true).bind(this);

        this.state = {
            speed: 5,
            boost: 0,
            axis: {
                direction: "",
                x: 0,
                y: 0,
            },
            zoom: {
                direction: "",
                y: 0,
                speed: 1,
            },
            manualFocus: 0,
            panSpeed: 1,
            tiltSpeed: 1,
            buttons: {
                DPadUp: false,
                DPadLeft: false,
                DPadDown: false,
                DPadRight: false,
                Y: false,
                LB: false,
                RB: false,
                Start: false,
                Back: false,
            },
        };
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener("keyup", this.handleKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
        window.removeEventListener("keyup", this.handleKeyUp);
    }

    axisPanTilt() {}

    handleKeyDown(event: KeyboardEvent) {
        if (event.repeat) {
            return;
        }
        switch (event.key) {
            case "w":
            case "W":
                this.buttonChangeHandler("DPadUp", true);
                break;
            case "a":
            case "A":
                this.buttonChangeHandler("DPadLeft", true);
                break;
            case "s":
            case "S":
                this.buttonChangeHandler("DPadDown", true);
                break;
            case "d":
            case "D":
                this.buttonChangeHandler("DPadRight", true);
                break;
            case "f":
                this.focusOnePush();
                break;
            case "F":
                this.focusModeAuto();
                break;
            case "ArrowUp":
                this.zoomIn();
                break;
            case "ArrowDown":
                this.zoomOut();
                break;
            case "ArrowLeft":
                this.focusNear();
                break;
            case "ArrowRight":
                this.focusFar();
                break;
            default:
                return true;
        }
        event.preventDefault();
    }

    handleKeyUp(event: KeyboardEvent) {
        switch (event.key) {
            case "w":
            case "W":
                this.buttonChangeHandler("DPadUp", false);
                break;
            case "a":
            case "A":
                this.buttonChangeHandler("DPadLeft", false);
                break;
            case "s":
            case "S":
                this.buttonChangeHandler("DPadDown", false);
                break;
            case "d":
            case "D":
                this.buttonChangeHandler("DPadRight", false);
                break;
            case "f":
                this.focusStop();
                break;
            case "ArrowUp":
            case "ArrowDown":
                this.zoomStop();
                break;
            case "ArrowLeft":
            case "ArrowRight":
                this.focusStop();
                break;
            default:
                return true;
        }
        event.preventDefault();
    }

    send(action, dict = {}) {
        const message = Object.assign(
            {},
            {
                action: action,
                speed: this.state.speed,
                camera: this.props.camera,
                panSpeed: this.state.panSpeed,
                tiltSpeed: this.state.tiltSpeed,
            },
            dict
        );
        console.log(message);
        return this.props.dispatch(message).catch(this.errorHandler.bind(this));
    }

    errorHandler(e: unknown) {
        console.error(e);
    }

    zoomIn() {
        this.send("ptzZoomIn");
    }

    zoomOut() {
        this.send("ptzZoomOut");
    }

    focusOnePush() {
        this.send("ptzFocusModeOnePush");
    }

    focusModeAuto() {
        this.send("ptzFocusModeAuto");
    }

    focusNear() {
        this.send("ptzFocusModeManual").then((res: WSAPIResponse) => {
            if (res.result && res.result === "success") {
                this.send("ptzFocusNear");
            }
        });
    }

    focusFar() {
        this.send("ptzFocusModeManual").then((res: WSAPIResponse) => {
            if (res.result && res.result === "success") {
                this.send("ptzFocusFar");
            }
        });
    }

    focusStop() {
        this.send("ptzFocusModeManual").then((res: WSAPIResponse) => {
            if (res.result && res.result === "success") {
                this.send("ptzFocusStop");
            }
        });
    }

    zoomStop() {
        this.send("ptzZoomStop");
    }

    panLeft() {
        this.send("ptzLeft");
    }

    panRight() {
        this.send("ptzRight");
    }

    tiltUp() {
        this.send("ptzUp");
    }

    tiltDown() {
        this.send("ptzDown");
    }

    panTiltStop() {
        this.send("ptzPanTiltStop");
    }

    upLeft() {
        this.send("ptzUpLeft");
    }

    upRight() {
        this.send("ptzUpRight");
    }

    downLeft() {
        this.send("ptzDownLeft");
    }

    downRight() {
        this.send("ptzDownRight");
    }

    test() {
        // this.send('ptzTest');
    }

    connectHandler(gamepadIndex) {
        console.log(`Gamepad ${gamepadIndex} connected !`);
    }

    disconnectHandler(gamepadIndex) {
        console.log(`Gamepad ${gamepadIndex} disconnected !`);
    }

    buttonChangeHandler(buttonName: GamepadButtonName, down: boolean) {
        this.setState({
            buttons: { ...this.state.buttons, [buttonName]: down },
        });
        if (down) {
            switch (buttonName) {
                case "DPadUp":
                    if (!!this.state.buttons.DPadLeft) {
                        this.upLeft();
                    } else if (!!this.state.buttons.DPadRight) {
                        this.upRight();
                    } else {
                        this.tiltUp();
                    }
                    break;
                case "DPadDown":
                    if (!!this.state.buttons.DPadLeft) {
                        this.downLeft();
                    } else if (!!this.state.buttons.DPadRight) {
                        this.downRight();
                    } else {
                        this.tiltDown();
                    }
                    break;
                case "DPadLeft":
                    if (!!this.state.buttons.DPadUp) {
                        this.upLeft();
                    } else if (!!this.state.buttons.DPadDown) {
                        this.downLeft();
                    } else {
                        this.panLeft();
                    }
                    break;
                case "DPadRight":
                    if (!!this.state.buttons.DPadUp) {
                        this.upRight();
                    } else if (!!this.state.buttons.DPadDown) {
                        this.downRight();
                    } else {
                        this.panRight();
                    }
                    break;
                case "Y":
                    this.send("ptzFocusModeOnePush");
                    break;
                case "Back":
                    if (this.state.buttons.Start) {
                        this.send("changePreviewInput", {
                            input: this.props.cameras.filter(
                                (c) => c.number === this.props.camera
                            )[0].input,
                        }).then(() => {
                            this.send("cut");
                        });
                    }
                    break;
                case "Start":
                    if (this.state.buttons.Back) {
                        this.send("changePreviewInput", {
                            input: this.props.cameras.filter(
                                (c) => c.number === this.props.camera
                            )[0].input,
                        }).then(() => {
                            this.send("cut");
                        });
                    }
                    break;
                default:
                    console.log("buttonChangeHandler", buttonName, down);
                    return;
            }
        } else {
            const camNumbers = this.props.cameras.map((c) => c.number);
            const ourIdx = camNumbers.indexOf(this.props.camera);
            switch (buttonName) {
                case "DPadUp":
                case "DPadDown":
                case "DPadLeft":
                case "DPadRight":
                    this.panTiltStop();
                    break;
                case "Y":
                    this.send("ptzFocusModeManual");
                    break;
                // Left / Right buttons swap cameras
                case "LB":
                    // Get the previous camera...
                    var prevIdx = ourIdx - 1;
                    // Wrap around if outside the array
                    if (prevIdx < 0) {
                        prevIdx = camNumbers.length - 1;
                    }
                    const prevCamera = camNumbers[prevIdx];
                    console.log("#############", ourIdx, prevIdx, camNumbers);
                    this.props.history.push(`/ptz/${prevCamera}`);
                    break;
                case "RB":
                    // Get the previous camera...
                    var nextIdx = ourIdx + 1;
                    // Wrap around if outside the array
                    if (nextIdx >= camNumbers.length) {
                        nextIdx = 0;
                    }
                    const nextCamera = camNumbers[nextIdx];
                    this.props.history.push(`/ptz/${nextCamera}`);
                    break;
                default:
                    console.log("buttonChangeHandler", buttonName, down);
                    return;
            }
        }
    }

    axisChangeHandler(axisName: GamepadAxisName, value: number) {
        const deadZoneValue = (value: number, calibration: number) =>
            Math.abs(value) < calibration ? 0 : Math.round(value * 1000) / 1000;
        const limitedValue = deadZoneValue(value, 0.2);
        switch (axisName) {
            case "RightStickX":
                if (limitedValue === this.state.axis.x) {
                    return;
                }
                this.setState({
                    axis: { ...this.state.axis, x: limitedValue },
                });
                this.updateAxis();
                break;
            case "RightStickY":
                if (limitedValue === this.state.axis.y) {
                    return;
                }
                this.setState({
                    axis: { ...this.state.axis, y: limitedValue },
                });
                this.updateAxis();
                break;
            case "LeftStickX":
                const focusValue = Math.round(deadZoneValue(value, 0.5) * 10);
                if (focusValue === this.state.manualFocus) {
                    return;
                }
                this.setState({ manualFocus: focusValue });
                this.updateFocus();
                break;
            case "LeftStickY":
                if (limitedValue === this.state.zoom.y) {
                    return;
                }
                this.setState({
                    zoom: { ...this.state.zoom, y: limitedValue },
                });
                this.updateZoom();
                break;
            case "LeftTrigger":
                if (limitedValue === this.state.boost) {
                    return;
                }
                this.setState({ boost: limitedValue });
                this.updateZoom();
                this.updateAxis();
                break;
            case "RightTrigger":
                break;
            default:
                return;
        }
    }

    updateAxis() {
        let left_right = ""; // Left, Right, or ''
        let up_down = ""; // Up, Down, or ''
        if (this.state.axis.x === 0 && this.state.axis.y === 0) {
            this.panTiltStop();
            return;
        }
        if (this.state.axis.x > 0) {
            // x > 0 (right)
            left_right = "Right";
        } else if (this.state.axis.x < 0) {
            // x < 0 (left)
            left_right = "Left";
        }

        if (this.state.axis.y > 0) {
            up_down = "Up";
        } else if (this.state.axis.y < 0) {
            up_down = "Down";
        }

        this.setState({
            axis: { ...this.state.axis, direction: `${up_down}${left_right}` },
        });
        this.send(`ptz${this.state.axis.direction}`, {
            panSpeed: this.axisToSpeed(this.state.axis.x),
            tiltSpeed: this.axisToSpeed(this.state.axis.y),
        });
    }

    updateFocus() {
        const deadZone = 5;
        let direction = "Stop";
        if (this.state.manualFocus > deadZone) {
            direction = "Far";
        } else if (this.state.manualFocus < -deadZone) {
            direction = "Near";
        }
        this.send(`ptzFocusModeManual`).then((res: WSAPIResponse) => {
            if (res.result === "success") {
                this.send(`ptzFocus${direction}`);
            } else {
                console.error("ptzFocusModeManual failed!", res);
            }
        });
    }

    updateZoom() {
        let in_out = ""; // In, Out, or ''
        if (this.state.zoom.y === 0) {
            this.zoomStop();
            return;
        }
        if (this.state.zoom.y > 0) {
            in_out = "In";
        } else if (this.state.zoom.y < 0) {
            in_out = "Out";
        }

        this.setState({
            zoom: {
                ...this.state.zoom,
                direction: in_out,
                speed: this.axisToSpeed(this.state.zoom.y),
            },
        });
        this.send(`ptzZoom${this.state.zoom.direction}`, {
            speed: this.state.zoom.speed,
        });
    }

    axisToSpeed(axis: number) {
        let multiplier = 2 + 0.2 * this.state.boost;
        const exp = Math.pow(100, multiplier * 0.4 * Math.abs(axis)) - 1;
        const rawPercent = Math.abs(axis) * 100;
        console.table({
            rawPercent,
            exp,
        });
        return Math.max(0, Math.min(Math.round(exp), 100));
    }

    render() {
        return (
            <Gamepad
                deadZone={0.07}
                onConnect={this.connectHandler}
                onDisconnect={this.disconnectHandler}
                onButtonChange={this.buttonChangeHandler}
                onAxisChange={this.axisChangeHandler}
            >
                <Container
                    fluid
                    style={{
                        backgroundColor: this.props.tally.program.includes(
                            this.props.input
                        )
                            ? "red"
                            : "transparent",
                    }}
                >
                    <Row>
                        <Col>
                            <Button
                                onMouseDown={this.upLeft}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.upLeft}
                                onTouchEnd={this.panTiltStop}
                                title="Up Left"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowUp}
                                    transform={{ rotate: -45 }}
                                />
                            </Button>
                            <Button
                                onMouseDown={this.tiltUp}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.tiltUp}
                                onTouchEnd={this.panTiltStop}
                                title="Up"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon icon={faArrowUp} />
                            </Button>
                            <Button
                                onMouseDown={this.upRight}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.upRight}
                                onTouchEnd={this.panTiltStop}
                                title="Up Right"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowUp}
                                    transform={{ rotate: 45 }}
                                />
                            </Button>
                            <br />
                            <Button
                                onMouseDown={this.panLeft}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.panLeft}
                                onTouchEnd={this.panTiltStop}
                                title="Left"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </Button>
                            <Button
                                variant="secondary"
                                onMouseUp={this.panTiltStop}
                                onTouchEnd={this.panTiltStop}
                                title="STOP Pan/Tilt movement"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </Button>
                            <Button
                                onMouseDown={this.panRight}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.panRight}
                                onTouchEnd={this.panTiltStop}
                                title="Right"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                            <br />
                            <Button
                                onMouseDown={this.downLeft}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.downLeft}
                                onTouchEnd={this.panTiltStop}
                                title="Down Left"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowDown}
                                    transform={{ rotate: 45 }}
                                />
                            </Button>
                            <Button
                                onMouseDown={this.tiltDown}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.tiltDown}
                                onTouchEnd={this.panTiltStop}
                                title="Down"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Button>
                            <Button
                                onMouseDown={this.downRight}
                                onMouseUp={this.panTiltStop}
                                onTouchStart={this.downRight}
                                onTouchEnd={this.panTiltStop}
                                title="Down Right"
                                style={{ margin: ".2em" }}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowDown}
                                    transform={{ rotate: -45 }}
                                />
                            </Button>
                        </Col>
                    </Row>
                    <LeftRightButtonRow
                        leftUp={() => {
                            this.setState({ speed: this.state.speed - 1 });
                        }}
                        leftText="Less Speed"
                        leftIcon={faMinus}
                        rightUp={() => {
                            this.setState({ speed: this.state.speed + 1 });
                        }}
                        rightText="More Speed"
                        rightIcon={faPlus}
                    >
                        Speed: {this.state.speed}
                    </LeftRightButtonRow>
                    <LeftRightButtonRow
                        leftDown={this.zoomOut}
                        leftUp={this.zoomStop}
                        leftText="Zoom Out"
                        leftIcon={faSearchMinus}
                        rightDown={this.zoomIn}
                        rightUp={this.zoomStop}
                        rightText="Zoom In"
                        rightIcon={faSearchPlus}
                    >
                        Zoom
                    </LeftRightButtonRow>
                    <LeftRightButtonRow
                        leftDown={this.focusNear}
                        leftUp={this.focusStop}
                        leftText="Focus Near"
                        leftIcon={faCaretLeft}
                        rightDown={this.focusFar}
                        rightUp={this.focusStop}
                        rightText="Focus Far"
                        rightIcon={faCaretRight}
                    >
                        Focus
                    </LeftRightButtonRow>
                    <LeftRightButtonRow
                        leftUp={this.focusModeAuto}
                        leftText="Auto Focus Mode"
                        leftIcon="AF"
                        rightDown={this.focusOnePush}
                        rightUp={this.focusStop}
                        rightText="One-Push Focus Trigger (hold)"
                        rightIcon="Focus"
                    />
                </Container>
            </Gamepad>
        );
    }
}

export default connector(PanTiltZoomControls);
