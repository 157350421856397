const w720h480 = { width: 704, height: 480 };
const w720h576 = { width: 704, height: 576 };
const w1280h720 = { width: 1280, height: 720 };
const w1920h1080 = { width: 1920, height: 1080 };
const w3840h2160 = { width: 3840, height: 2160 };

export const VideoModeSize = [
    w720h480,
    w720h576,
    w720h480,
    w720h576,

    w1280h720,
    w1280h720,
    w1920h1080,
    w1920h1080,
    w1920h1080,
    w1920h1080,
    w1920h1080,
    w1920h1080,
    w1920h1080,
    w1920h1080,

    w3840h2160,
    w3840h2160,
    w3840h2160,
    w3840h2160,
    w3840h2160,
    w3840h2160,
];

export enum VideoMode {}

export interface TallyState {
    program: number[];
    preview: number[];
}

export interface InputState {
    inputId: number;
    longName: string;
    shortName: string;
    externalPorts: number[];
    isExternal: boolean;
    externalPortType: number;
    internalPortType: number;
    sourceAvailability: number;
    meAvailability: number;
}

export interface MediaPlayerState {
    clipIndex: number;
    sourceType: number;
    stillIndex: number;
}

export interface StillState {
    filename: string;
    hash: string;
    isUsed: boolean;
}

export interface MediaState {
    clipPool: any[];
    players: MediaPlayerState[];
    stillPool: StillState[];
}

export interface ATEMState {
    videoMode: VideoMode;
    connected: boolean;
    state: {};
    tally: TallyState;
    inputs: InputState[];
    media: MediaState;
}

export const ATEM_UPDATE_STATE = "ATEM_UPDATE_STATE";
export const ATEM_UPDATE_TALLY = "ATEM_UPDATE_TALLY";
export const ATEM_UPDATE_INPUTS = "ATEM_UPDATE_INPUTS";
export const ATEM_UPDATE_MEDIA = "ATEM_UPDATE_MEDIA";

interface ATEMUpdateTallyAction {
    type: typeof ATEM_UPDATE_TALLY;
    program: number[];
    preview: number[];
}

interface ATEMUpdateInputsAction {
    type: typeof ATEM_UPDATE_INPUTS;
    inputs: InputState[];
}

interface ATEMUpdateMediaAction {
    type: typeof ATEM_UPDATE_MEDIA;
    media: MediaState;
}

interface ATEMUpdateAction {
    type: typeof ATEM_UPDATE_STATE;
    state: {};
}

export type ATEMActionTypes =
    | ATEMUpdateAction
    | ATEMUpdateTallyAction
    | ATEMUpdateInputsAction
    | ATEMUpdateMediaAction;
