import { v4 as uuidv4 } from "uuid";
import { WSAPIRequest, WSAPIResponse } from "@lbcde.org/websocket-api";

export type MessageEventHandler = (event: MessageEvent) => void;
export type ConnectEventHandler = (event: Event) => void;
export type DisconnectEventHandler = (event: CloseEvent) => void;

interface Request {
    request: WSAPIRequest;
    onReceive: (response: WSAPIResponse) => void;
    onError: (error: Error) => void;
}

class WSClient {
    url: string;
    onMessageHandler: MessageEventHandler;
    onConnectHandler: ConnectEventHandler;
    onDisconnectHandler: DisconnectEventHandler;
    instance: WebSocket;
    number: number = 0; // Message number
    autoReconnectInterval: number = 2 * 1000; // ms
    requests: {
        [_id: string]: Request;
    };

    constructor(
        url: string,
        onMessageHandler: MessageEventHandler,
        onConnectHandler: DisconnectEventHandler = null,
        onDisconnectHandler: DisconnectEventHandler = null
    ) {
        this.url = url;
        this.onMessageHandler = onMessageHandler;
        this.onConnectHandler = onConnectHandler;
        this.onDisconnectHandler = onDisconnectHandler;
        this.requests = {};
        this.open();
    }

    open() {
        if (this.instance && this.instance.readyState !== WebSocket.CLOSED) {
            try {
                this.instance.close();
            } catch (e) {
                console.error(`Error closing old websocket: ${e}`);
            }
        }
        this.instance = new WebSocket(this.url);
        this.instance.onopen = this.onopen.bind(this);
        this.instance.onmessage = this.onmessage.bind(this);
        this.instance.onclose = this.onclose.bind(this);
        this.instance.onerror = (e: ErrorEvent) => {
            switch (e.error) {
                case "ECONNREFUSED":
                    this.reconnect();
                    break;
                default:
                    this.onerror(e);
                    break;
            }
        };
    }

    close() {
        this.instance.close();
    }

    send(request: WSAPIRequest): Promise<WSAPIResponse> {
        request._id = uuidv4();
        return new Promise((resolve, reject) => {
            this.requests[request._id] = {
                // Add time sent so we can periodically
                // delete requests over a threshold
                request: request,
                onReceive: resolve,
                onError: reject,
            };
            try {
                this.instance.send(JSON.stringify(request));
                // might resolve later if a message comes in with a matching _id
            } catch (e) {
                if (e === DOMException.INVALID_STATE_ERR) {
                    // The connection is not currently OPEN.
                    reject((e as Error).message);
                } else if (e === DOMException.SYNTAX_ERR) {
                    // The data is a string that has unpaired surrogates.
                    // ... whatever that means
                    reject((e as Error).message);
                }
            }
        });
    }

    reconnect() {
        console.log(`WSClient: retry in ${this.autoReconnectInterval}ms`);
        setTimeout(() => {
            console.log("WSClient: reconnecting...");
            this.open();
        }, this.autoReconnectInterval);
    }

    onopen(event: Event) {
        if (this.onConnectHandler !== null) {
            this.onConnectHandler(event);
        }
        console.log("WSClient: open", event);
    }

    onmessage(event: MessageEvent) {
        const response: WSAPIResponse = JSON.parse(event.data);
        if (response._id === undefined) {
            // If no ID, send to the outer message handler
            this.onMessageHandler(event);
            return; // and stop
        } else {
            const request = this.requests[response._id];
            if (request === undefined) {
                // No known request for this ID
                console.warn("WSClient: unknown message", response);
                return;
            }
            // We have an ID, and the RequestSlot for it
            // Send the response to the onReceive function
            request.onReceive(response);

            // Delete the request since we handled it.
            delete this.requests[response._id];
            return;
        }
    }

    onerror(event: ErrorEvent) {
        console.log("WSClient: error", event.message);
    }

    onclose(event: CloseEvent) {
        if (this.onDisconnectHandler !== null) {
            this.onDisconnectHandler(event);
        }
        for (let _id of Object.keys(this.requests)) {
            this.requests[_id].onError(new Error());
        }
        switch (event.code) {
            case 1000: // CLOSE_NORMAL
                console.log("WebSocket: closed");
                break;
            case 4000: // CLOSE_AUTHENTICATION_REQUIRED
                break;
            default:
                // Abnormal closure
                console.error(event);
                this.reconnect();
                break;
        }
        console.log("WebSocketClient: closed", arguments);
    }
}

export default WSClient;
