import {
    ATEMState,
    ATEM_UPDATE_STATE,
    ATEMActionTypes,
    ATEM_UPDATE_TALLY,
    InputState,
    ATEM_UPDATE_INPUTS,
    ATEM_UPDATE_MEDIA,
    MediaState,
} from "./types";

export const updateTally = (program: number[], preview: number[]) => ({
    type: ATEM_UPDATE_TALLY,
    program: program,
    preview: preview,
});

export const updateInputs = (inputs: InputState[]) => ({
    type: ATEM_UPDATE_INPUTS,
    inputs: inputs,
});

export const updateMedia = (media: MediaState) => ({
    type: ATEM_UPDATE_MEDIA,
    media: media,
});
