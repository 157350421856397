import React from "react";

interface VuMeterGraphicProps extends React.HTMLAttributes<HTMLDivElement> {
    level: number;
}

const VuMeterGraphic: React.FunctionComponent<VuMeterGraphicProps> = ({
    level,
    ...props
}) => {
    const strokeWidth = 8;
    const graphicWidth = 25;
    const gradations = 8;
    const lineOffset = 100 / gradations;
    const lines = Array.from(Array(gradations).keys());
    return (
        <svg
            role="img"
            viewBox={`0 0 ${graphicWidth} 100`}
            xmlns="http://www.w3.org/2000/svg"
            style={{
                width: "0.5em",
                verticalAlign: "-0.225em",
                fontSize: "1.3333333em",
                lineHeight: "0.75em",
                height: "1em",
                display: "inline-block",
            }}
        >
            {lines.map((line) => {
                const lineLevel = (gradations - line) * (100 / gradations);
                let color = "grey";
                // 30 dBa, but goes up to 100
                if (level >= lineLevel) {
                    if (lineLevel >= 90) {
                        color = "red";
                    } else if (lineLevel >= 70) {
                        color = "orange";
                    } else if (lineLevel >= 40) {
                        color = "yellow";
                    } else {
                        color = "green";
                    }
                }
                return (
                    <line
                        key={line}
                        x1={0}
                        x2={graphicWidth}
                        y1={line * lineOffset + strokeWidth / 2}
                        y2={line * lineOffset + strokeWidth / 2}
                        stroke={color}
                        strokeWidth={strokeWidth}
                    />
                );
            })}
        </svg>
    );
};

interface VuMeterProps extends React.HTMLAttributes<HTMLDivElement> {
    meters: number[]; // one array element per channel, number is dBu?
}

export const VuMeter: React.FunctionComponent<VuMeterProps> = ({
    meters,
    ...props
}) => {
    return (
        <span {...props}>
            {meters.map((chanLevel, idx) => (
                <VuMeterGraphic key={idx} level={chanLevel} />
            ))}
        </span>
    );
};
