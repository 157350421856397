import {
    AUTH_API_LOADED,
    AUTH_BEGIN,
    AUTH_FAILURE,
    AUTH_SUCCESS,
    AuthActionTypes,
    AUTH_SIGNED_OUT,
} from "./types";
declare var gapi: any;

export const authBegin = () => ({
    type: AUTH_BEGIN,
});

export const authSuccess = (user) => ({
    type: AUTH_SUCCESS,
    user: user,
});

export const authFailure = (error) => ({
    type: AUTH_FAILURE,
    message: error,
});

export const authSignedOut = () => ({
    type: AUTH_SIGNED_OUT,
});

export function authAPILoaded() {
    return (dispatch) => {
        gapi.client
            .init({
                clientId:
                    "196462731636-6har7tvce96fhgbst1um6cqcl26sht48.apps.googleusercontent.com",
                scope: "profile",
            })
            .then(() => {
                // Attach event listener for isSignedIn
                gapi.auth2.getAuthInstance().isSignedIn.listen((isSignedIn) => {
                    console.log("isSignedIn listener...");
                    if (isSignedIn) {
                        let name = gapi.auth2
                            .getAuthInstance()
                            .currentUser.get()
                            .getBasicProfile()
                            .getName();
                        dispatch(authSuccess(name));
                    } else {
                        dispatch(authSignedOut());
                    }
                });
                dispatch(authenticate());
            });
    };
}

export function authenticate() {
    return (dispatch) => {
        dispatch(authBegin());
        // gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
        const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
        if (isSignedIn) {
            let name = gapi.auth2
                .getAuthInstance()
                .currentUser.get()
                .getBasicProfile()
                .getName();
            dispatch(authSuccess(name));
        } else {
            gapi.auth2
                .getAuthInstance()
                .signIn()
                .catch((error) => {
                    dispatch(authFailure(error));
                });
        }
    };
}
