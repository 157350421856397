import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

export default function NotFound(props) {
    return (
        <Container fluid>
            <Row>
                <Col style={{ textAlign: "center" }}>
                    <h1>404</h1>
                    <h3>Page Not Found</h3>
                    <Link to="/">Start over?</Link>
                </Col>
            </Row>
        </Container>
    );
}
