import {
    GraphicsState,
    GraphicsActionTypes,
    GRAPHICS_UPLOAD_BEGIN,
    GRAPHICS_UPLOAD_SUCCESS,
    GRAPHICS_UPLOAD_FAILURE,
    CHANGE_TEMPLATE,
    UPDATE_VARIABLE,
    CHANGE_MEDIASLOT,
    FONT_LOADED,
    FONT_LOAD_FAILED,
} from "./types";

import { LowerThirdTemplate } from "../../graphics/templates/LowerThirdTemplate";
import { PlainLowerThirdTemplate } from "../../graphics/templates/PlainLowerThirdTemplate";
import { SongInfoTemplate } from "../../graphics/templates/SongInfoTemplate";
import { ServiceCoverTemplate } from "../../graphics/templates/ServiceCoverTemplate";
import { RedBlueCardTemplate } from "../../graphics/templates/RedBlueCardTemplate";
import { RedBlueL3Template } from "../../graphics/templates/RedBlueL3Template";

const initialState: GraphicsState = {
    templates: [
        LowerThirdTemplate,
        PlainLowerThirdTemplate,
        SongInfoTemplate,
        ServiceCoverTemplate,
        RedBlueCardTemplate,
        RedBlueL3Template,
    ],
    template: undefined,
    mediaSlot: 1,
    variables: undefined,
    uploadFailed: false,
    uploadFailureMessage: "",
    fontLoaded: false,
    fontLoadFailed: false,
};

export function graphicsReducer(
    state = initialState,
    action: GraphicsActionTypes
): GraphicsState {
    switch (action.type) {
        case FONT_LOAD_FAILED:
            return {
                ...state,
                fontLoadFailed: true,
                fontLoaded: false,
            };
        case FONT_LOADED:
            return {
                ...state,
                fontLoaded: action.fontLoaded,
            };
        case CHANGE_TEMPLATE:
            const oldSameVariables = { ...state.variables };
            let newVariables = {};
            let newVariableKeys = [];
            if (action.template) {
                newVariables = action.template.variables;
                newVariableKeys = Object.keys(newVariables);
                Object.keys(oldSameVariables).forEach((k) => {
                    if (!newVariableKeys.includes(k)) {
                        delete oldSameVariables[k];
                    }
                });
            }
            return {
                ...state,
                template: action.template,
                variables: {
                    ...newVariables,
                    ...oldSameVariables,
                },
            };
        case CHANGE_MEDIASLOT:
            return {
                ...state,
                mediaSlot: action.mediaSlot,
            };
        case UPDATE_VARIABLE:
            const new_variable = {
                ...state.variables[action.variable],
                value: action.value,
            };
            return {
                ...state,
                variables: {
                    ...state.variables,
                    ...{ [action.variable]: new_variable },
                },
            };
        case GRAPHICS_UPLOAD_FAILURE:
            return {
                ...state,
                uploadFailed: true,
                uploadFailureMessage: action.message,
            };
        case GRAPHICS_UPLOAD_SUCCESS:
            return {
                ...state,
                uploadFailed: false,
                uploadFailureMessage: "",
            };
        case GRAPHICS_UPLOAD_BEGIN:
            return {
                ...state,
                uploadFailed: false,
                uploadFailureMessage: "",
            };
        default:
            return state;
    }
}
