import React from "react";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

import Card from "react-bootstrap/Card";
import PanTiltZoomControls from "./ptz/PanTiltZoomControls";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "./store";
import Modal from "react-bootstrap/Modal";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";

import { LinkContainer } from "react-router-bootstrap";
import { Camera } from "./store/ptz/types";
import { AddToastFunction, ToastIcon } from "./toasts";
import { WSMP4Video } from "./ptz/WSMP4Video";

const mapState = (state: RootState) => ({
    tally: state.atem.tally,
});

const mapDispatch = (dispatch) => ({});

const connector = connect(mapState, mapDispatch);

interface PTZControllerProps {
    dispatch: any;
    input: number;
    camera: number;
    cameras: Camera[];
    addToast: AddToastFunction;
}

interface PTZControllerState {
    command_sent: boolean;
    lastCommand: string;
    showHelp: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector> & PTZControllerProps;

class PTZController extends React.Component<
    PropsFromRedux,
    PTZControllerState
> {
    cameraInputs = {
        1: 6,
        2: 7,
    };
    presets = [
        {
            name: "Pulpit Wide",
            number: 1,
        },
        {
            name: "Pulpit Close",
            number: 2,
        },
        {
            name: "Piano",
            number: 3,
        },
        {
            name: "Choir",
            number: 4,
        },
        {
            name: "Below Pulpit Wide",
            number: 5,
        },
        {
            name: "Below Pulpit Close",
            number: 6,
        },
        {
            name: "Below Pulpit Close",
            number: 7,
        },
        {
            name: "Below Pulpit Close",
            number: 8,
        },
        {
            name: "Below Pulpit Close",
            number: 9,
        },
    ];

    constructor(props) {
        super(props);
        this.recallPreset = this.recallPreset.bind(this);
        this.setPreset = this.setPreset.bind(this);
        this.setPower = this.setPower.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.state = {
            command_sent: false,
            lastCommand: "",
            showHelp: false,
        };
    }

    setPreset(camera, preset) {
        this.setState({
            command_sent: true,
            lastCommand: `setPreset("${camera}", "${preset}")`,
        });
        this.props.addToast({
            title: "Preset Set",
            body: `Setting preset ${preset} on camera ${this.cameraInputs[camera]}...`,
            timeout: 3000,
        });
        fetch(`/api/ptz/${camera}/set/${preset}`, {
            credentials: "include",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.result === "success") {
                    this.props.addToast({
                        title: "Preset Set",
                        body: `Successfully set preset ${preset} on camera ${this.cameraInputs[camera]}!`,
                        timeout: 3000,
                    });
                } else {
                    this.props.addToast({
                        title: "Preset Set",
                        icon: ToastIcon.Error,
                        body: `Failed to set preset ${preset} on camera ${
                            this.cameraInputs[camera]
                        }!\n${JSON.stringify(res)}`,
                        timeout: 15000, // Let errors hang around a while
                    });
                }
                this.setState({ command_sent: false });
            });
        // But we'll also reset state after 2 seconds in case we have an error...
        setTimeout(() => {
            this.setState({ command_sent: false });
        }, 2000);
    }

    recallPreset(camera, preset) {
        this.setState({
            command_sent: true,
            lastCommand: `recallPreset("${camera}", "${preset}")`,
        });
        this.props.addToast({
            title: "Preset Recall",
            body: `Recalling preset ${preset} on camera ${this.cameraInputs[camera]}...`,
            timeout: 3000,
        });
        fetch(`/api/ptz/${camera}/recall/${preset}`, {
            credentials: "include",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.result === "success") {
                    this.props.addToast({
                        title: "Preset Recall",
                        body: `Successfully recalled preset ${preset} on camera ${this.cameraInputs[camera]}!`,
                        timeout: 3000,
                    });
                } else {
                    this.props.addToast({
                        title: "Preset Recall",
                        icon: ToastIcon.Error,
                        body: `Failed to recall preset ${preset} on camera ${
                            this.cameraInputs[camera]
                        }!\n${JSON.stringify(res)}`,
                        timeout: 15000, // Let errors hang around a while
                    });
                }
                this.setState({ command_sent: false });
            });
        // But we'll also reset state after 5 seconds in case we have an error...
        setTimeout(() => {
            this.setState({ command_sent: false });
        }, 5000);
    }

    setPower(camera, state) {
        fetch(`/api/ptz/${camera}/power/${state}`, {
            credentials: "include",
            redirect: "follow",
        });
    }

    handleKeyDown(e: KeyboardEvent) {
        // A few helpers since KeyboardEvent makes this difficult...
        const noModifiers = !(
            e.metaKey ||
            e.ctrlKey ||
            e.altKey ||
            e.shiftKey ||
            e.repeat
        );
        const shiftKeyOnly =
            e.shiftKey && !(e.metaKey || e.ctrlKey || e.altKey || e.repeat);
        const altKeyOnly =
            e.altKey && !(e.metaKey || e.ctrlKey || e.shiftKey || e.repeat);

        // Help function
        if (e.code === "KeyH" || (e.code === "Slash" && shiftKeyOnly)) {
            e.preventDefault();
            this.setState((prev) => ({ showHelp: !prev.showHelp }));
            return;
        }

        // Preview function
        if (e.code === "KeyP") {
            e.preventDefault();
            this.props.dispatch({
                action: "changePreviewInput",
                input: this.props.input,
            });
            return;
        }

        // Take function
        if (e.code === "Enter" && shiftKeyOnly) {
            e.preventDefault();
            this.props.dispatch({
                action: "cut",
            });
            return;
        }

        // Single-digit shortcuts
        const matches = e.code.match(/^(Digit|Numpad)([0-9])$/);
        if (matches === null) {
            return; // Only dealing with digits/numpad here.
        }
        // const location = matches[1]; // Numpad or Digit
        const key = matches[2];
        const digit = parseInt(key, 10); // "can't" throw since matches[2] can only be 0-9

        if (noModifiers) {
            // Recall Preset
            e.preventDefault();
            if (this.state.command_sent) {
                return false;
            }
            this.recallPreset(this.props.camera, key);
        } else if (altKeyOnly) {
            // Set Preset
            e.preventDefault();
            if (this.state.command_sent) {
                return false;
            }
            this.setPreset(this.props.camera, key);
        } else if (shiftKeyOnly) {
            // Change Camera
            e.preventDefault();
            this.props.history.push(`/ptz/${digit}`);
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeyDown);
    }

    getAirStatus(): string {
        if (this.props.tally.program.includes(this.props.input)) {
            return "On Air";
        }
        if (this.props.tally.preview.includes(this.props.input)) {
            return "Preview";
        }
        return "Off Air";
    }

    render() {
        const airStatus = this.getAirStatus();
        return (
            <div className="PTZController" tabIndex={0}>
                <Container
                    fluid
                    style={{
                        backgroundColor: this.props.tally.program.includes(
                            this.props.input
                        )
                            ? "red"
                            : "transparent",
                    }}
                >
                    <Row>
                        <Col>
                            <h2 className="text-center">
                                Camera {this.cameraInputs[this.props.camera]}:{" "}
                                {airStatus}
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {[1, 2].map((camera: number, index) => {
                                const camInput = this.cameraInputs[camera];
                                const isProgram = this.props.tally.program.includes(
                                    camInput
                                );
                                const isPreview = this.props.tally.preview.includes(
                                    camInput
                                );
                                let variant: any = "light";
                                if (isProgram) {
                                    variant = "program";
                                } else if (isPreview) {
                                    variant = "preview";
                                }
                                return (
                                    <LinkContainer
                                        key={camera}
                                        to={this.props.match.path
                                            .toString()
                                            .replace(":camera", camera)}
                                    >
                                        <Button variant={variant}>
                                            Camera {camInput}
                                        </Button>
                                    </LinkContainer>
                                );
                            })}
                            <Button className="float-right" onClick={() => {
                                this.setState((prev) => ({ showHelp: true }));
                            }}>
                                Help
                            </Button>
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col>
                            <Row>
                                {this.presets.map((preset) => (
                                    <Col
                                        xs={4}
                                        md={6}
                                        lg={4}
                                        key={preset.number}
                                    >
                                        <Card
                                            style={{ marginBottom: "1.8em" }}
                                            border={
                                                this.state.command_sent
                                                    ? "light"
                                                    : "primary"
                                            }
                                            onClick={(
                                                e: React.MouseEvent<HTMLDivElement>
                                            ) => {
                                                if (e.altKey) {
                                                    this.setPreset(
                                                        this.props.camera,
                                                        preset.number
                                                    );
                                                } else {
                                                    this.recallPreset(
                                                        this.props.camera,
                                                        preset.number
                                                    );
                                                }
                                            }}
                                        >
                                            <div
                                                style={{ position: "relative" }}
                                            >
                                                <Card.Img as={"svg"}>
                                                    <text x={"50%"} y={"50%"} alignmentBaseline={"middle"} fontSize={"4em"}>{preset.number}</text>
                                                </Card.Img>
                                                <Card.ImgOverlay>
                                                    <Card.Title
                                                        style={{
                                                            fontSize: "1vw",
                                                        }}
                                                    >
                                                        {preset.number}.{" "}
                                                        {preset.name}
                                                    </Card.Title>
                                                </Card.ImgOverlay>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col xs={12} md={3} xl={2}>
                            <Row>
                                <PanTiltZoomControls
                                    dispatch={this.props.dispatch}
                                    camera={this.props.camera}
                                    input={this.props.input}
                                    cameras={this.props.cameras}
                                    {...this.props}
                                />
                            </Row>
                            <Row>
                                <ButtonToolbar>
                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                        }}
                                    >
                                        Camera Power:
                                    </InputGroup.Text>
                                    <ButtonGroup>
                                        <Button
                                            disabled={this.state.command_sent}
                                            onClick={(e) =>
                                                this.setPower(
                                                    this.props.camera,
                                                    1
                                                )
                                            }
                                        >
                                            On
                                        </Button>
                                        <Button
                                            disabled={this.state.command_sent}
                                            onClick={(e) =>
                                                this.setPower(
                                                    this.props.camera,
                                                    0
                                                )
                                            }
                                        >
                                            Off
                                        </Button>
                                    </ButtonGroup>
                                </ButtonToolbar>
                            </Row>
                        </Col>
                    </Row>
                    <Modal
                        show={this.state.showHelp}
                        onHide={() => this.setState({ showHelp: false })}
                        size="lg"
                    >
                        <Modal.Body>
                            <h3>Presets</h3>
                            <b>Recall</b> a preset by pressing the number (0-9)
                            on the keyboard that you want to recall. Or click
                            the appropriate box using the mouse.
                            <br />
                            <b>Set</b> a preset by holding <i>ALT</i> and
                            pressing the number (0-9) on the keyboard that you
                            want to set to the current position. Or hold{" "}
                            <i>ALT</i> and click the appropriate box using the
                            mouse.
                            <h3>Pan/Tilt Movement</h3>
                            Use the WASD keys to move the camera up/down and
                            left/right:
                            <br />
                            <code>W</code> - Tilt Up, <code>A</code> - Pan Left,{" "}
                            <code>S</code> - Tilt Down, <code>D</code> - Pan
                            Right
                            <br />
                            <h3>Zoom</h3>
                            Use the up/down arrow keys to zoom in/out.
                            <h3>Focus</h3>
                            Use the left/right arrow keys to focus near/far.
                        </Modal.Body>
                        <Modal.Footer>
                            Hint: Press ESC to close this help window.
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        );
    }
}

export default connector(PTZController);
