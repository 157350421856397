import React from "react";
// import logo from './logo.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { WSAPIContext } from "./WSAPIContext";
import WSClient from "./WSClient";
import { WSAPIRequest, WSAPIResponse } from "@lbcde.org/websocket-api";
import GraphicsDesigner from "./GraphicsDesigner";
import Dashboard from "./Dashboard";
import PTZController from "./PTZController";
import NotFound from "./errors/NotFound";
import Tally from "./Tally";
import ATEMMedia from "./graphics/ATEMMedia";
import Presets from "./Presets";

import {
    Navbar,
    Nav,
    NavDropdown,
    Spinner,
    Toast as ToastElement,
} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import { debounce } from "underscore";
import { v4 as uuidv4 } from "uuid";

import { LinkContainer } from "react-router-bootstrap";

import { RootState } from "./store";
import { connect, ConnectedProps } from "react-redux";

import { updateTally, updateInputs, updateMedia } from "./store/atem/actions";
import { TallyState } from "./store/atem/types";

import { authAPILoaded } from "./store/auth/actions";
import { faBroadcastTower, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { AddToastOptions, Toast, ToastIcon } from "./toasts";
import { UltraStreamStatusCode } from "./encoder/EncoderControls";
import { VuMeter } from "./encoder/VUMeter";

declare global {
    interface Window {
        onGapiLoad: Function;
    }
}

declare var gapi: any;

const mapState = (state: RootState) => ({
    tally: state.atem.tally,
    user: state.auth.user,
    isSignedIn: state.auth.isSignedIn,
});

const mapDispatch = {
    updateTally: updateTally,
    updateInputs: updateInputs,
    updateMedia: updateMedia,
    authAPILoaded: authAPILoaded,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

enum WebSocketStatus {
    Connected,
    Disconnected,
}

interface WebSocketState {
    client: WSClient;
    status: WebSocketStatus;
}

interface ProjectorState {
    power: number;
    input: number;
    avmute: number;
}

interface EncoderState {
    "box-name": string;
    "channel-count": number;
    "check-upgrade": {
        "client-id": string;
        result: number;
    };
    "cur-status": number;
    "cur-time": string;
    "disk-test": object;
    downgrade: object;
    eth: object;
    "format-status": object;
    "input-signal": object;
    "last-rec-status": number;
    "last-rec-status2": number;
    "live-status": {
        result: number;
    };
    "living-test": object;
    "lock-user": unknown[];
    "message-errors": unknown[];
    "message-infos": unknown[];
    "message-warns": unknown[];
    mobile: object;
    "preview-user": unknown[];
    "rec-status": object;
    result: number;
    sd: object;
    sysstat: object;
    upgrade: object;
    "upgrade-status": object;
    usb: object;
    vumeters: number[];
    wifi: object;
}

interface AppState {
    webSocket: WebSocketState;
    projector: ProjectorState;
    encoder: EncoderState;
    toasts: Toast[];
}

class App extends React.Component<PropsFromRedux, AppState> {
    debouncedSetState: React.Dispatch<React.SetStateAction<AppState>> &
        _.Cancelable;

    constructor(props) {
        super(props);
        this.state = {
            webSocket: {
                client: null,
                status: WebSocketStatus.Disconnected,
            },
            projector: {
                power: -1,
                input: -1,
                avmute: -1,
            },
            encoder: {
                "box-name": "",
                "channel-count": 0,
                "check-upgrade": {
                    "client-id": "",
                    result: 0,
                },
                "cur-status": 0,
                "cur-time": "",
                "disk-test": {},
                downgrade: {},
                eth: {},
                "format-status": {},
                "input-signal": {},
                "last-rec-status": 0,
                "last-rec-status2": 0,
                "live-status": {
                    result: 0,
                },
                "living-test": {},
                "lock-user": [],
                "message-errors": [],
                "message-infos": [],
                "message-warns": [],
                "preview-user": [],
                "rec-status": {},
                "upgrade-status": {},
                mobile: {},
                result: 0,
                sd: {},
                sysstat: {},
                upgrade: {},
                usb: {},
                vumeters: [0, 0],
                wifi: {},
            },
            toasts: [],
        };
        this.dispatch = this.dispatch.bind(this);
        this.addToast = this.addToast.bind(this);
        this.setProjectorPower = this.setProjectorPower.bind(this);
        this.debouncedSetState = debounce(this.setState.bind(this), 42); // 42ms ~= 24fps, 33ms ~= 30fps
    }

    addToast({
        title = "Untitled Message",
        body = "Disembodied Message",
        icon = ToastIcon.Info,
        timeout = 0,
    }: AddToastOptions): Toast {
        const newToast: Toast = {
            id: uuidv4(),
            created: moment(),
            icon,
            title,
            body,
            timeout,
        };

        this.setState((prev) => ({
            toasts: [...prev.toasts, newToast],
        }));
        return newToast;
    }

    dispatch(request: WSAPIRequest): Promise<WSAPIResponse> {
        return this.state.webSocket.client.send(request);
    }

    setProjectorPower(projector, state) {
        const stateName = state === 1 ? "On" : "Off";
        this.addToast({
            title: "Projector Power",
            body: `Setting Power to ${stateName}`,
            timeout: 3000,
        });
        return fetch(`/api/projector/${projector}/power/${state}`, {
            credentials: "include",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((data) => {
                this.addToast({
                    title: "Projector Power",
                    body: data.success
                        ? `Projector powering ${stateName.toLowerCase()}...`
                        : `Error turning projector ${stateName.toLowerCase()}.`,
                    timeout: 3000,
                });
            });
    }

    setProjectorAVMute(projector, state) {
        const stateName = state === 31 ? "Blank" : "Unblank";
        this.addToast({
            title: "Projector AV Mute",
            body: `${stateName}ing projector...`,
            timeout: 3000,
        });
        return fetch(`/api/projector/${projector}/avmute/${state}`, {
            credentials: "include",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((data) => {
                this.addToast({
                    title: "Projector AV Mute",
                    body: data.success
                        ? `Projector is now ${stateName.toLocaleLowerCase()}ed.`
                        : `Error ${stateName.toLocaleLowerCase()}ing projector.`,
                    timeout: 3000,
                });
            });
    }

    onWebSocketMessage(msg_event: MessageEvent) {
        const msg = JSON.parse(msg_event.data);

        // Catch errors where we "throw" them as errorType/Error
        if (msg.error && msg.errorType) {
            // @ts-ignore: Unreachable code error
            // throw new window[msg.errorType](msg.error);
            this.addToast({
                icon: ToastIcon.Error,
                title: "Error",
                body: `${msg.errorType}:\n\n${msg.error}`,
                timeout: 15 * 1000,
            });
        }
        if (msg.state) {
            let path = msg.path; // if state is defined, path better be too...
            let state = msg.state;
            if (
                state.video &&
                state.video.mixEffects &&
                state.video.mixEffects[0]
            ) {
                const tally: TallyState = {
                    program: [],
                    preview: [],
                };
                if (state.video.mixEffects[0].programTally) {
                    tally.program = state.video.mixEffects[0].programTally;
                }
                if (state.video.mixEffects[0].previewTally) {
                    tally.preview = state.video.mixEffects[0].previewTally;
                }
                this.props.updateTally(tally.program, tally.preview);
            }

            if (state.inputs) {
                this.props.updateInputs(state.inputs);
            }

            if (state.media) {
                this.props.updateMedia(state.media);
            }

            if (path === "video.mixEffects.0.transition") {
                console.log(path);
                this.debouncedSetState(state);
            }
            this.debouncedSetState.cancel();
            this.setState(state);
        }
        if (msg.projector !== undefined) {
            // Projector state update
            console.log("Got projector state update...");
            const idx = msg.projector;
            if (idx !== 0) {
                console.error("Unknown projector index!");
                return;
            }
            this.setState({
                projector: msg.status,
            });
        }
        if (msg.encoder !== undefined) {
            // Encoder state update
            console.log("Got encoder state update...");
            const idx = msg.encoder;
            if (idx !== 0) {
                console.error("Unknown encoder index!");
                return;
            }
            this.setState({
                encoder: msg.status,
            });
        }
    }

    componentDidMount() {
        const gapiScript = document.createElement("script");
        gapiScript.src = "https://apis.google.com/js/api.js?onload=onGapiLoad";
        window.onGapiLoad = () => {
            const onAuthApiLoad = () => {
                this.props.authAPILoaded();
            };
            gapi.load("client:auth2", { callback: onAuthApiLoad });
        };
        document.body.appendChild(gapiScript);

        let proto = "ws://";
        if (window.location.protocol === "https:") {
            proto = "wss://";
        }
        let portOrPath = "/ws";
        if (["localhost", "172.16.49.11"].includes(window.location.hostname)) {
            portOrPath = ":8999/ws"; // dev uses separate port
        }
        let webSocket = new WSClient(
            `${proto}${window.location.hostname}${portOrPath}`,
            this.onWebSocketMessage.bind(this),
            () => {
                this.setState((prev) => ({
                    webSocket: {
                        ...prev.webSocket,
                        status: WebSocketStatus.Connected,
                    },
                }));
            },
            () => {
                this.setState((prev) => ({
                    webSocket: {
                        ...prev.webSocket,
                        status: WebSocketStatus.Disconnected,
                    },
                }));
            }
        );
        this.setState((prev) => ({
            webSocket: {
                ...prev.webSocket,
                client: webSocket,
            },
        }));
        // @ts-ignore
        window.ws = webSocket;
    }

    componentWillUnmount() {
        if (this.state.webSocket.client) {
            this.state.webSocket.client.close();
        }
    }

    render() {
        if (!this.props.isSignedIn) {
            return (
                <div className="App">
                    Signing in... <Spinner animation="border" />
                </div>
            );
        }
        const cams = [
            {
                number: 1,
                input: 6,
            },
            {
                number: 2,
                input: 7,
            },
        ];
        return (
            <div className="App">
                <WSAPIContext.Provider value={this.dispatch}>
                    <Router>
                        <Navbar bg="dark" variant="dark" expand="lg">
                            <LinkContainer to="/">
                                <Navbar.Brand>Lighthouse Studio</Navbar.Brand>
                            </LinkContainer>
                            <Navbar.Toggle aria-controls="main-nav" />
                            <Navbar.Collapse id="main-nav">
                                <Nav className="mr-auto">
                                    <LinkContainer to="/" exact>
                                        <Nav.Link>Dashboard</Nav.Link>
                                    </LinkContainer>
                                    <NavDropdown
                                        title="Graphics"
                                        id="collasible-nav-dropdown"
                                    >
                                        <LinkContainer to="/graphics/templates">
                                            <NavDropdown.Item>
                                                Templates
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                        <LinkContainer to="/graphics/atem-media">
                                            <NavDropdown.Item>
                                                ATEM Media
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                    </NavDropdown>
                                    <LinkContainer to="/ptz">
                                        <Nav.Link>PTZ</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/tally">
                                        <Nav.Link>Tally</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/presets">
                                        <Nav.Link>Presets</Nav.Link>
                                    </LinkContainer>
                                </Nav>
                            </Navbar.Collapse>
                            <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text
                                    style={{
                                        paddingRight: "1em",
                                    }}
                                >
                                    <VuMeter
                                        meters={this.state.encoder.vumeters}
                                        style={{
                                            paddingRight: "0.5em",
                                        }}
                                    />
                                </Navbar.Text>
                                <NavDropdown
                                    id="liveStatusDropdown"
                                    title={
                                        <FontAwesomeIcon
                                            icon={faBroadcastTower}
                                            size="lg"
                                            color={
                                                this.state.encoder[
                                                    "live-status"
                                                ].result ===
                                                UltraStreamStatusCode.retLivingConnected
                                                    ? "green"
                                                    : "red"
                                            }
                                            title={`Encoder is ${
                                                this.state.encoder[
                                                    "live-status"
                                                ].result ===
                                                UltraStreamStatusCode.retLivingConnected
                                                    ? ""
                                                    : "NOT "
                                            }sending data to YouTube.`}
                                        />
                                    }
                                >
                                    {this.state.encoder["live-status"]
                                        .result ===
                                    UltraStreamStatusCode.retLivingConnected ? (
                                        <NavDropdown.Item onClick={() => {
                                            return fetch(`/api/encoder/0/live/0`, {
                                                credentials: "include",
                                                redirect: "follow",
                                            }).then((res) => res.json());
                                        }}>
                                            Turn off Stream
                                        </NavDropdown.Item>
                                    ) : (
                                        <NavDropdown.Item onClick={() => {
                                            return fetch(`/api/encoder/0/live/1`, {
                                                credentials: "include",
                                                redirect: "follow",
                                            }).then((res) => res.json());
                                        }}>
                                            Start Streaming!
                                        </NavDropdown.Item>
                                    )}
                                </NavDropdown>
                                <Navbar.Text
                                    style={{
                                        paddingRight: "1em",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircle}
                                        size="lg"
                                        color={
                                            this.state.webSocket.status ===
                                            WebSocketStatus.Connected
                                                ? "green"
                                                : "red"
                                        }
                                        title={
                                            "WebSocket is " +
                                            WebSocketStatus[
                                                this.state.webSocket.status
                                            ]
                                        }
                                    />
                                </Navbar.Text>
                                <Navbar.Text>
                                    Signed in as:{" "}
                                    <a href="#login">{this.props.user}</a>
                                </Navbar.Text>
                            </Navbar.Collapse>
                        </Navbar>
                        <div
                            aria-live="polite"
                            aria-atomic="true"
                            style={{
                                position: "relative",
                                zIndex: 999,
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: "0.4em",
                                    right: "0.4em",
                                }}
                            >
                                {this.state.toasts.map((toast) => (
                                    <ToastElement
                                        key={toast.id}
                                        style={{ minWidth: "25em" }}
                                        delay={toast.timeout}
                                        autohide={
                                            toast.timeout > 0 ? true : false
                                        }
                                        onClose={() => {
                                            this.setState((prev) => ({
                                                toasts: prev.toasts.filter(
                                                    (t) => {
                                                        return t !== toast;
                                                    }
                                                ),
                                            }));
                                        }}
                                    >
                                        <ToastElement.Header>
                                            {toast.icon}
                                            &nbsp;
                                            <strong className="mr-auto">
                                                {toast.title}
                                            </strong>
                                            &nbsp;
                                            <small>
                                                {toast.created.fromNow()}
                                            </small>
                                        </ToastElement.Header>
                                        <ToastElement.Body>
                                            {toast.body}
                                        </ToastElement.Body>
                                    </ToastElement>
                                ))}
                            </div>
                        </div>
                        <Switch>
                            <Route path="/graphics/templates">
                                <GraphicsDesigner state={this.state} />
                            </Route>
                            <Route
                                path="/ptz/:camera"
                                render={(props) => {
                                    const cam = parseInt(
                                        props.match.params.camera,
                                        10
                                    );
                                    // Maps camera number to input number on the switcher
                                    const inputs = {
                                        1: 6,
                                        2: 7,
                                    };
                                    return (
                                        <PTZController
                                            key={cam}
                                            dispatch={this.dispatch}
                                            addToast={this.addToast}
                                            input={inputs[cam]}
                                            camera={cam}
                                            cameras={cams}
                                            {...props}
                                        />
                                    );
                                }}
                            ></Route>
                            <Route path="/ptz">
                                <Redirect to="/ptz/1" />
                            </Route>
                            <Route path="/tally" component={Tally} />
                            <Route path="/presets" component={Presets} />
                            <Route
                                path="/graphics/atem-media"
                                component={ATEMMedia}
                            />
                            <Route path="/" exact>
                                <Dashboard
                                    setProjectorPower={this.setProjectorPower}
                                    setProjectorAVMute={this.setProjectorAVMute}
                                    projectorPower={this.state.projector.power}
                                    projectorInput={this.state.projector.input}
                                    projectorAVMute={
                                        this.state.projector.avmute
                                    }
                                    dispatch={this.dispatch}
                                    addToast={this.addToast}
                                    encoder={this.state.encoder}
                                    cameras={cams}
                                />
                            </Route>
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </WSAPIContext.Provider>
            </div>
        );
    }
}

export default connector(App);
