import {
    faExclamationTriangle,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Moment } from "moment";
import React from "react";

export interface Toast {
    id: string;
    icon: JSX.Element;
    title: string;
    body: string;
    created: Moment;
    timeout?: number;
}

export interface AddToastOptions {
    title: string;
    body: string;
    icon?: JSX.Element;
    timeout?: number;
}

export type AddToastFunction = (options: AddToastOptions) => {};

export const ToastIcon: {
    [key: string]: JSX.Element;
} = {
    Info: <FontAwesomeIcon icon={faInfoCircle} color="blue" />,
    Error: <FontAwesomeIcon icon={faExclamationTriangle} color="red" />,
};
