import React from "react";

import Spinner from "react-bootstrap/Spinner";
import { RootState } from "../store";
import { connect, ConnectedProps } from "react-redux";

import Image from "react-bootstrap/Image";
import { Card, Row, Col, Container, Badge } from "react-bootstrap";

const mapState = (state: RootState) => ({
    media: state.atem.media,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

function ATEMMediaStill(props) {
    return (
        <Col>
            <Card className="bg-dark text-white">
                <Card.Header>{props.slot}</Card.Header>
                <Card.Body>
                    <Card.Img
                        alt={`Image in slot ${props.slot}`}
                        src={`https://via.placeholder.com/256x143.png?text=${props.slot}: ${props.fileName}`}
                    />
                    <Card.ImgOverlay>
                        <Card.Title>{props.fileName}</Card.Title>
                        <Card.Text>{props.hash}</Card.Text>
                    </Card.ImgOverlay>
                </Card.Body>
            </Card>
        </Col>
    );
}

function ATEMMediaPlayer(props) {
    return (
        <Col xs="12">
            <Card className="pb-6 bg-dark text-white">
                <Card.Header>
                    Media Player {props.player}{" "}
                    <Badge pill variant="danger">
                        Secondary
                    </Badge>
                </Card.Header>
                <Card.Body>
                    <Card.Img
                        alt={`Image in slot ${props.slot}`}
                        src={`https://via.placeholder.com/256x143.png?text=Media Player ${props.player}`}
                    />
                    <Card.ImgOverlay>
                        <Card.Title>{props.fileName}</Card.Title>
                        <Card.Text>{props.hash}</Card.Text>
                    </Card.ImgOverlay>
                </Card.Body>
            </Card>
        </Col>
    );
}

function ATEMMedia(props: PropsFromRedux) {
    console.log(props.media);
    if (props.media.stillPool.length === 0) {
        return <Spinner animation="border" />;
    }
    return (
        <Container fluid>
            <Row>&nbsp;</Row>
            <Row>
                <Col>
                    <Row xs={2} sm={4} md={5} xl={10}>
                        {props.media.stillPool.map((still, idx) => (
                            <ATEMMediaStill
                                slot={idx + 1}
                                key={idx}
                                {...still}
                            ></ATEMMediaStill>
                        ))}
                    </Row>
                </Col>
                <Col xs={12} sm={3}>
                    <Row>
                        {props.media.players.map((player, idx) => (
                            <ATEMMediaPlayer
                                player={idx + 1}
                                key={idx}
                                {...player}
                            ></ATEMMediaPlayer>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default connector(ATEMMedia);
