import { graphicsReducer } from "./graphics/reducers";
import { ATEMReducer } from "./atem/reducers";
import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./auth/reducers";

const rootReducer = combineReducers({
    graphics: graphicsReducer,
    atem: ATEMReducer,
    auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = createStore(rootReducer, applyMiddleware(thunk));
