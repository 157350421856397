import moment from "moment";

import { Liquid } from "liquidjs";
import { escape_non_ascii } from "./templates/util";

const liquid = new Liquid();

liquid.registerFilter("textWidth", (text: string, fontString: string) => {
    const canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    ctx.font = fontString;
    return ctx.measureText(text).width.toPrecision(5);
});

liquid.registerFilter("encodeForSVG", (text: string) => {
    return escape_non_ascii(text);
});

liquid.registerFilter("formatDate", (date_string: string, format: string) => {
    const m = moment(date_string);
    return m.format(format);
});

export default liquid;
