import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { RootState } from "./store";
import { connect, ConnectedProps } from "react-redux";
import { faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import "./Tally.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapState = (state: RootState) => ({
    program: state.atem.tally.program,
    preview: state.atem.tally.preview,
    inputs: state.atem.inputs,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TallyLight(props) {
    const [wakeLock, setWakeLock] = useState<WakeLockSentinel>(null);

    let input = props.inputs[props.inputId];
    if (input === undefined) {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    }

    const doubleClickHandler = (event) => {
        if ("wakeLock" in navigator) {
            if (wakeLock === null) {
                window.navigator.wakeLock.request("screen").then(setWakeLock);
            } else {
                wakeLock.release().then(() => setWakeLock(null));
            }
        }
        if (document.fullscreenElement === event.currentTarget) {
            document.exitFullscreen();
        } else {
            document.exitFullscreen().catch(() => {});
            event.currentTarget.requestFullscreen();
        }
    }



    const isProgram = props.program.includes(input.inputId);
    const isPreview = props.preview.includes(input.inputId);

    let colorClass = "";

    if (isProgram) {
        colorClass = "program";
    } else if (isPreview) {
        colorClass = "preview";
    }
    return (
        <div
            className={`TallyLight ${colorClass}`}
            onDoubleClick={doubleClickHandler}
        >
            <FontAwesomeIcon icon={wakeLock === null ? faLockOpen : faLock} /> {input.longName}
        </div>
    );
}

export default connector(TallyLight);
