import React from "react";
import { Image } from "react-bootstrap";

interface ProgramViewerState {
    url: string;
    time: string;
}

export class ProgramViewer extends React.PureComponent<
    any,
    ProgramViewerState
> {
    timer: number;

    constructor(props) {
        super(props);
        this.state = {
            url: "",
            time: "",
        };
        this.tick = this.tick.bind(this);
    }

    componentDidMount() {
        this.timer = window.setInterval(this.tick, 250);
    }

    componentWillUnmount() {
        window.clearInterval(this.timer);
    }

    tick() {
        this.setState({ time: Date.now().toString() });
    }

    render() {
        return (
            <Image
                alt="Program video preview"
                src={`/api/getProgramSnapshot?${this.state.time}`}
                fluid
                thumbnail
            />
        );
    }
}
