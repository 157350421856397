import {
    GraphicsTemplate,
    GraphicsVariableType,
} from "../../store/graphics/types";
import RedBlueBG from "./images/RedBlueBG";
import Poppins from "./fonts/Poppins";

const next_sunday = new Date();
next_sunday.setUTCDate(
    next_sunday.getDate() + ((((6 - next_sunday.getDay()) % 7) + 1) % 7)
);
next_sunday.setUTCHours(10);
next_sunday.setUTCMinutes(30);
next_sunday.setUTCSeconds(0);
next_sunday.setUTCMilliseconds(0);

export const RedBlueL3Template: GraphicsTemplate = {
    slug: "red_blue_l3",
    title: "Red/Blue Lower Third",
    variables: {
        left_text: {
            name: "Left Name",
            value: "Josh Canedy",
            type: GraphicsVariableType.Text,
        },
        right_text: {
            name: "Right Name",
            value: "Tobe Witmer",
            type: GraphicsVariableType.Text,
        },
        mainHeight: {
            name: "Text Height",
            value: "80",
            type: GraphicsVariableType.Number,
        },
    },
    name: `Service Cover: {{ date }}`,
    background: false,
    loadFonts: {
        Poppins: Poppins,
    },
    template: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
{%- assign width = 1920 -%}
{%- assign height = 1080 -%}
{%- assign logo_diameter = 100 -%}
{%- assign textOffset = -100 -%}
{%- assign fontFamily = "Poppins, 'Adobe Gothic', Arial, sans-serif" -%}

{%- assign boxInset = 100 -%}
{%- assign bottomInset = 250 -%}

{%- assign mainFontPx = mainHeight | times: 0.75 -%}
{%- assign mainFontPxHalf = mainFontPx | divided_by: 2 -%}
{%- assign mainTextFont = mainFontPx | append: "px " | append: fontFamily | prepend: "lighter " -%}
{%- assign mainFontSizeHalf = mainFontPx | divided_by: 2 -%}

{%- assign boxRLPadding = 80 -%}
{%- assign halfBoxRLPadding = boxRLPadding | divided_by: 2 -%}

{%- assign leftTextCY = height | minus: bottomInset -%}
{%- assign leftTextY = leftTextCY | plus: mainFontSizeHalf -%}
{%- assign leftTextW = left_text | textWidth: mainTextFont -%}
{%- assign leftBoxW = leftTextW | plus: boxRLPadding -%}
{%- assign leftBoxX = boxInset -%}
{%- assign leftTextX = leftBoxX | plus: halfBoxRLPadding -%}

{%- assign rightTextCY = height | minus: bottomInset -%}
{%- assign rightTextY = rightTextCY | plus: mainFontSizeHalf -%}
{%- assign rightTextW = right_text | textWidth: mainTextFont -%}
{%- assign rightBoxW = rightTextW | plus: boxRLPadding -%}
{%- assign rightBoxX = width | minus: boxInset | minus: rightBoxW | minus: halfBoxRLPadding -%}
{%- assign rightTextX = rightBoxX | plus: halfBoxRLPadding -%}

{%- assign boxH = mainFontPx | times: 2 -%}
{%- assign boxHHalf = boxH | divided_by: 2 -%}

{%- assign boxYTextOffset = mainFontPx | times: 0.1 -%}
{%- assign boxY = leftTextCY | minus: boxHHalf | plus: boxYTextOffset -%}
                <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
                <defs>
                    <style type="text/css">
                        @font-face {
                            font-family: "Poppins";
                            src: url("${Poppins}");
                        }
                    </style>
                    <pattern id="redBlueBG" patternUnits="userSpaceOnUse" width="1920" height="1080">
                        <image href="${RedBlueBG}" width="1920px" height="1080px" x="0" y="0" opacity="0.5" />
                    </pattern>
                </defs>
                
                {% if left_text != '' %}
                <rect x="{{leftBoxX}}" y="{{boxY}}" width="{{leftBoxW}}" height="{{boxH}}"
                    style="stroke: white;stroke-width:5;fill:url(#redBlueBG);" />
                <text x="{{leftTextX}}" y="{{leftTextY}}" width="1300" height="600" fill="white"
                    style="font-size: {{mainFontPx}}px;font-weight: lighter;font-family:{{fontFamily}};">{{ left_text | encodeForSVG }}</text>
                {% endif %}

                {% if right_text != '' %}
                <rect x="{{rightBoxX}}" y="{{boxY}}" width="{{rightBoxW}}" height="{{boxH}}"
                    style="stroke: white;stroke-width:5;fill:url(#redBlueBG);" />
                <text x="{{rightTextX}}" y="{{rightTextY}}" width="1300" height="600" fill="white"
                    style="font-size: {{mainFontPx}}px;font-weight: lighter;font-family:{{fontFamily}};">
                    {{ right_text | encodeForSVG }}
                </text>
                {% endif %}
        </svg>`,
};
