import React from "react";

import Form from "react-bootstrap/Form";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";
import { updateVariable } from "../store/graphics/actions";
import { GraphicsVariableType } from "../store/graphics/types";

const mapState = (state: RootState) => ({
    template: state.graphics.template,
    variables: state.graphics.variables,
});

const mapDispatch = (dispatch) => ({
    changeVariables: function (e) {
        const slug = e.currentTarget.id;
        const type = this.variables[slug].type;
        let new_value = e.currentTarget.value;
        switch (type) {
            case GraphicsVariableType.Bool:
                new_value = e.currentTarget.checked.toString();
                break;
            default:
            // noop, let new_value fall through unaltered
        }
        dispatch(updateVariable(slug, new_value));
    },
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TemplateVariableForm(props: PropsFromRedux) {
    let inputs = [<span key="key">(No template selected)</span>];
    if (props.variables !== undefined) {
        inputs = Object.keys(props.variables).map((slug) => {
            const type = props.variables[slug].type;
            let control;
            switch (type) {
                case GraphicsVariableType.DateTime:
                    control = (
                        <Form.Control
                            type="datetime-local"
                            value={props.variables[slug].value}
                            onChange={props.changeVariables.bind(props)}
                        />
                    );
                    break;
                case GraphicsVariableType.Bool:
                    control = (
                        <Form.Check
                            type="switch"
                            label={
                                props.variables[slug].value === "true"
                                    ? "On"
                                    : "Off"
                            }
                            checked={props.variables[slug].value === "true"}
                            onChange={props.changeVariables.bind(props)}
                        />
                    );
                    break;
                default:
                    const inputType = GraphicsVariableType[type].toLowerCase();
                    control = (
                        <Form.Control
                            type={inputType}
                            value={props.variables[slug].value}
                            onChange={props.changeVariables.bind(props)}
                        />
                    );
            }
            return (
                <Form.Group key={slug} controlId={slug}>
                    <Form.Label>{props.variables[slug].name}</Form.Label>
                    {control}
                </Form.Group>
            );
        });
    }
    return <Form className="TemplateVariableForm">{inputs}</Form>;
}

export default connector(TemplateVariableForm);
