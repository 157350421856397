import {
    GraphicsTemplate,
    GraphicsVariableType,
} from "../../store/graphics/types";

export const PlainLowerThirdTemplate: GraphicsTemplate = {
    title: "Plain Lower Third",
    slug: "plain_lower_third",
    variables: {
        upper_line: {
            name: "Upper Line",
            value: "Tobe Witmer",
            type: GraphicsVariableType.Text,
        },
        lower_line: {
            name: "Lower Line",
            value: "Senior Pastor",
            type: GraphicsVariableType.Text,
        },
    },
    name: `L3: {{ upper_line }}`,
    background: false,
    template: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
            <defs>
                  <style type="text/css">
                  @import url('https://fonts.googleapis.com/css?family=Arvo|Lato|Raleway|Text+Me+One&amp;display=block');
                  </style>
            </defs>
            <filter id="textDropShadow">
                  <feOffset in="SourceAlpha" dx="4" dy="4"></feOffset>
                  <feGaussianBlur stdDeviation="8" result="DROP"></feGaussianBlur>
                  <feFlood flood-color="#000" result="COLOR"></feFlood>
                  <feComposite in="DROP" in2="COLOR" operator="in" result="SHADOW1"></feComposite>
                  <feComponentTransfer in="SHADOW1" result="SHADOW">
                     <feFuncA type="table" tableValues="0 0.75"></feFuncA>
                  </feComponentTransfer>
                  <feMerge>
                     <feMergeNode in="SHADOW"></feMergeNode>
                     <feMergeNode in="SourceGraphic"></feMergeNode>
                  </feMerge>
            </filter>
            <text x="200" y="850" font-size="92px" fill="white" filter="url(#textDropShadow)" style="font-family:'Text Me One', sans-serif;">{{ upper_line | encodeForSVG }}</text>
            <text x="200" y="898" font-size="42px" fill="white" filter="url(#textDropShadow)"  style="font-family:'Text Me One', sans-serif;">{{ lower_line | encodeForSVG }}</text>
         </svg>`,
};

export default PlainLowerThirdTemplate;
