import {
    ATEMState,
    ATEMActionTypes,
    ATEM_UPDATE_STATE,
    ATEM_UPDATE_TALLY,
    ATEM_UPDATE_INPUTS,
    ATEM_UPDATE_MEDIA,
} from "./types";

const initialState: ATEMState = {
    videoMode: 11,
    connected: false,
    state: {},
    tally: {
        program: [],
        preview: [],
    },
    inputs: [],
    media: {
        clipPool: [],
        players: [],
        stillPool: [],
    },
};

export function ATEMReducer(
    state = initialState,
    action: ATEMActionTypes
): ATEMState {
    switch (action.type) {
        case ATEM_UPDATE_STATE:
            return {
                ...state,
                state: action.state,
            };
        case ATEM_UPDATE_TALLY:
            return {
                ...state,
                tally: {
                    program: action.program,
                    preview: action.preview,
                },
            };
        case ATEM_UPDATE_INPUTS:
            return {
                ...state,
                inputs: action.inputs,
            };
        case ATEM_UPDATE_MEDIA:
            return {
                ...state,
                media: action.media,
            };
        default:
            return state;
    }
}
