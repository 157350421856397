import {
    AuthState,
    AuthActionTypes,
    AUTH_BEGIN,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    AUTH_API_LOADED,
    AUTH_SIGNED_OUT,
} from "./types";

const initialState: AuthState = {
    authenticated: false,
    authFailed: false,
    authFailureMessage: "",
    isSignedIn: false,
    user: undefined,
};

export function authReducer(
    state = initialState,
    action: AuthActionTypes
): AuthState {
    switch (action.type) {
        case AUTH_API_LOADED:
            return {
                ...state,
            };
        case AUTH_SIGNED_OUT:
            return {
                ...state,
                authenticated: false,
                user: undefined,
                isSignedIn: false,
                authFailed: false,
                authFailureMessage: "",
            };
        case AUTH_FAILURE:
            return {
                ...state,
                authFailed: true,
                authFailureMessage: action.message,
            };
        case AUTH_SUCCESS:
            return {
                ...state,
                authenticated: true,
                user: action.user,
                isSignedIn: true,
                authFailed: false,
                authFailureMessage: "",
            };
        case AUTH_BEGIN:
            return {
                ...state,
                authFailed: false,
                authFailureMessage: "",
            };
        default:
            return state;
    }
}
