import React from "react";

import Button, { ButtonProps } from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import "./Tally.css";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import { RootState } from "./store";

import { connect, ConnectedProps } from "react-redux";
import TallyLight from "./TallyLight";

const mapState = (state: RootState) => ({
    program: state.atem.tally.program,
    preview: state.atem.tally.preview,
    inputs: state.atem.inputs,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Tally(props) {
    let match = useRouteMatch();
    let tally_ = <></>;
    if (props.inputs) {
        console.table(props.program);
        tally_ = (
            <>
                <Row>
                    <Col>
                        {Object.values(props.inputs)
                            .filter(
                                (i: any) =>
                                    i !== null &&
                                    i.inputId > 0 &&
                                    i.inputId < 1000
                            )
                            .map((input: any, index) => {
                                const isProgram = props.program.includes(
                                    input.inputId
                                );
                                const isPreview = props.preview.includes(
                                    input.inputId
                                );
                                let variant: any = "light";
                                if (isProgram) {
                                    variant = "program";
                                } else if (isPreview) {
                                    variant = "preview";
                                }
                                return (
                                    <LinkContainer
                                        key={input.inputId}
                                        to={`${match.path}/${input.inputId}`}
                                    >
                                        <Button variant={variant}>
                                            {input.shortName}
                                        </Button>
                                    </LinkContainer>
                                );
                            })}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Switch>
                            <Route
                                path={`${match.path}/:inputId`}
                                render={(props) => (
                                    <TallyLight
                                        inputId={props.match.params.inputId}
                                    />
                                )}
                            />
                            <Route path={match.path}>
                                <h3>Select an input.</h3>
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </>
        );
    }
    return (
        <div className="Tally">
            <Container fluid>
                {props.inputs ? (
                    tally_
                ) : (
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )}
            </Container>
        </div>
    );
}

export default connector(Tally);
