import React, { useContext } from "react";

import Button from "react-bootstrap/Button";
import { RootState } from "./store";

import { connect, ConnectedProps } from "react-redux";
import { WSAPIContext } from "./WSAPIContext";

const mapState = (state: RootState) => ({
    program: state.atem.tally.program,
    preview: state.atem.tally.preview,
    inputs: state.atem.inputs,
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Presets(props) {
    const sendWSMsg = useContext(WSAPIContext);
    return (
        <div className="Presets">
            <h3>Lyrics Presets</h3>
            <Button
                onClick={() => {
                    sendWSMsg({
                        action: "setUpstreamKeyerFillSource",
                        keyer: 0, // zero-indexed
                        input: 1, // Projection
                    });
                    sendWSMsg({
                        action: "setUpstreamKeyerType",
                        settings: {
                            flyEnabled: true,
                            mixEffectKeyType: 3, // DVE
                        },
                        keyer: 0, // zero-indexed
                    });
                    sendWSMsg({
                        action: "setUpstreamKeyerDVESettings",
                        settings: {
                            // BORDER/SHADOW
                            borderEnabled: false, // turning it off is sufficient
                            shadowEnabled: false,

                            // MASK
                            maskTop: 6.32 * 1000,
                            maskBottom: 0,
                            maskLeft: 0,
                            maskRight: 0,
                            maskEnabled: true,

                            // POSITION
                            positionX: 0,
                            positionY: -5.6 * 1000,

                            // SIZE
                            sizeX: 1 * 1000,
                            sizeY: 1 * 1000,

                            // MISC
                            rotation: 0,
                        },
                        keyer: 0,
                    });
                }}
            >
                Middle of Projector in L3 position
            </Button>
            <h3>Downstream Keys</h3>
            <Button
                onClick={() => {
                    sendWSMsg({
                        action: "setDownstreamKeyFillSource",
                        keyer: 0, // zero-indexed
                        input: 4, // Camera 4 (openlp)
                    });
                    sendWSMsg({
                        action: "setDownstreamKeyCutSource",
                        keyer: 0, // zero-indexed
                        input: 0, // Black
                    });
                    sendWSMsg({
                        action: "setDownstreamKeyMaskSettings",
                        settings: {
                            enabled: true,
                            top: -2.75 * 1000, // ugh...
                            bottom: -9.0 * 1000,
                            left: -16.0 * 1000,
                            right: 16.0 * 1000,
                        },
                        keyer: 0, // zero-indexed
                    });
                    sendWSMsg({
                        action: "setDownstreamKeyGeneralProperties",
                        settings: {
                            clip: 0,
                            gain: 715, // out of 1000
                            invert: false,
                            preMultiply: false,
                        },
                        keyer: 0,
                    });
                }}
            >
                DSK1: OpenLP Lower Third
            </Button>
            <Button
                onClick={() => {
                    sendWSMsg({
                        action: "setDownstreamKeyFillSource",
                        keyer: 1, // zero-indexed
                        input: 3010, // MP1
                    });
                    sendWSMsg({
                        action: "setDownstreamKeyCutSource",
                        keyer: 1, // zero-indexed
                        input: 3011, // MP1 Key
                    });
                    sendWSMsg({
                        action: "setDownstreamKeyMaskSettings",
                        settings: {
                            enabled: false,
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                        },
                        keyer: 1, // zero-indexed
                    });
                    sendWSMsg({
                        action: "setDownstreamKeyGeneralProperties",
                        settings: {
                            clip: 0,
                            gain: 0,
                            invert: false,
                            preMultiply: true,
                        },
                        keyer: 1,
                    });
                }}
            >
                DSK2: Media Player 1 with transparency
            </Button>
        </div>
    );
}

export default connector(Presets);
