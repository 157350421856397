import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { ProgramViewer } from "./ProgramViewer";
import PanTiltZoomControls from "./ptz/PanTiltZoomControls";
import EncoderControls from "./encoder/EncoderControls";

class Dashboard extends React.Component<any> {
    projectorPowerName(number) {
        switch (number) {
            case 0:
                return "Off";
            case 1:
                return "On";
            case 2:
                return "Cooling";
            case 3:
                return "WarmUp";
            default:
                return "Unknown";
        }
    }

    projectorInputName(number) {
        switch (number) {
            case 32:
                return "HDMI";
            default:
                return "UNKNOWN";
        }
    }

    projectorAVMuteName(number) {
        switch (number) {
            case 30:
                return "Off";
            case 31:
                return "On";
            default:
                return "UNKNOWN";
        }
    }

    render() {
        return (
            <div className="GraphicsDesigner">
                <Container fluid>
                    <Row>
                        <Col
                            sm={{ span: 12, offset: 0, order: 1 }}
                            md={{ span: 5, offset: 0, order: 12 }}
                            lg={4}
                            xl={3}
                        >
                            <h4>Encoder</h4>
                            <Container fluid>
                                <Row>
                                    <ProgramViewer />
                                </Row>
                                <Row>
                                    <EncoderControls
                                        index="0"
                                        encoder={this.props.encoder}
                                    />
                                </Row>
                            </Container>
                        </Col>
                        <Col
                            xs={{ span: 4, order: 2 }}
                            md={{ span: 3, order: 2 }}
                            lg
                        >
                            <PanTiltZoomControls
                                dispatch={this.props.dispatch}
                                camera={1}
                                input={6}
                                cameras={this.props.cameras}
                            />
                        </Col>
                        <Col
                            sm={{ span: 8, order: 3 }}
                            md={{ span: 3, order: 3 }}
                            lg
                        >
                            <h4>Auditorium Projector</h4>
                            <Container className="projectorControls">
                                <Row>
                                    <Col sm="3" md="5" lg="2">
                                        Status:
                                    </Col>
                                    <Col xs="2" sm="3" lg="3">
                                        <Badge>
                                            {this.projectorPowerName(
                                                this.props.projectorPower
                                            )}
                                        </Badge>
                                    </Col>
                                    <Col xs>
                                        <ButtonGroup size="lg">
                                            <Button
                                                variant="outline-primary"
                                                onClick={(e) => {
                                                    this.props.setProjectorPower(
                                                        0,
                                                        1
                                                    );
                                                }}
                                                disabled={
                                                    this.props.projectorPower == 1 || this.props.projectorPower > 3
                                                }
                                            >
                                                On
                                            </Button>
                                            <Button
                                                variant="outline-primary"
                                                onClick={(e) => {
                                                    this.props.setProjectorPower(
                                                        0,
                                                        0
                                                    );
                                                }}
                                                disabled={
                                                    this.props
                                                        .projectorPower !== 1
                                                }
                                            >
                                                Off
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" md="5" lg="2">
                                        Blank:
                                    </Col>
                                    <Col xs="2" sm="3" lg="3">
                                        <Badge>
                                            {this.projectorAVMuteName(
                                                this.props.projectorAVMute
                                            )}
                                        </Badge>
                                    </Col>
                                    <Col xs>
                                        <ButtonGroup size="lg">
                                            <Button
                                                variant="outline-primary"
                                                onClick={(e) => {
                                                    this.props.setProjectorAVMute(
                                                        0,
                                                        31
                                                    );
                                                }}
                                                disabled={
                                                    this.props
                                                        .projectorAVMute !== 30
                                                }
                                            >
                                                On
                                            </Button>
                                            <Button
                                                variant="outline-primary"
                                                onClick={(e) => {
                                                    this.props.setProjectorAVMute(
                                                        0,
                                                        30
                                                    );
                                                }}
                                                disabled={
                                                    this.props
                                                        .projectorAVMute !== 31
                                                }
                                            >
                                                Off
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3" md="5" lg="2">
                                        Input:
                                    </Col>
                                    <Col xs="2" sm="3" lg="3">
                                        <Badge>
                                            {this.projectorInputName(
                                                this.props.projectorInput
                                            )}
                                        </Badge>
                                    </Col>
                                    <Col xs></Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Dashboard;
