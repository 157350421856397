import {
    GraphicsTemplate,
    GraphicsVariableType,
} from "../../store/graphics/types";
import logo from "./images/logo";
import RedBlueBG from "./images/RedBlueBG";
import Poppins from "./fonts/Poppins";

const next_sunday = new Date();
next_sunday.setUTCDate(
    next_sunday.getDate() + ((((6 - next_sunday.getDay()) % 7) + 1) % 7)
);
next_sunday.setUTCHours(10);
next_sunday.setUTCMinutes(30);
next_sunday.setUTCSeconds(0);
next_sunday.setUTCMilliseconds(0);

export const RedBlueCardTemplate: GraphicsTemplate = {
    slug: "red_blue_card",
    title: "Red/Blue Card",
    variables: {
        date: {
            name: "Date",
            value: next_sunday.toISOString().substring(0, 16),
            type: GraphicsVariableType.DateTime,
        },
        main_text: {
            name: "Main Text (in the box)",
            value: "Morning Service",
            type: GraphicsVariableType.Text,
        },
        lower_text: {
            name: "Lower Text (under the box)",
            value: "Live Stream",
            type: GraphicsVariableType.Text,
        },
        show_date: {
            name: "Show Date",
            value: "true",
            type: GraphicsVariableType.Bool,
        },
    },
    name: `Service Cover: {{ date }}`,
    background: false,
    loadFonts: {
        Poppins: Poppins,
    },
    template: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
{%- assign width = 1920 -%}
{%- assign height = 1080 -%}
{%- assign logo_diameter = 100 -%}
{%- assign textOffset = -100 -%}
{%- assign fontFamily = "Poppins, 'Adobe Gothic', Arial, sans-serif" -%}

{%- assign autoTestH = 200 -%}
{%- assign autoTestFont = autoTestH | append: "px " | append: fontFamily | prepend: "bold " -%}
{%- assign autoTestW = main_text | textWidth: autoTestFont -%}
{%- assign autoTestRatio = width | divided_by: autoTestW -%}

{%- assign mainHeight = autoTestRatio | times: autoTestH | at_most: autoTestH -%}

{%- assign mainFontPx = mainHeight | times: 0.75 -%}
{%- assign mainFontPxHalf = mainFontPx | divided_by: 2 -%}
{%- assign mainTextFont = mainFontPx | append: "px " | append: fontFamily | prepend: "bold " -%}
{%- assign mainFontSizeHalf = mainFontPx | divided_by: 2 -%}

{%- assign lowerHeight = 68 -%}
{%- assign lowerFontPx = lowerHeight | times: 0.75 -%}
{%- assign lowerTextFont = lowerFontPx | append: "px " | append: fontFamily | prepend: "100 " -%}
{%- assign lowerFontSizeHalf = lowerFontPx | divided_by: 2 -%}

{%- assign mainTextCY = height | divided_by: 2 | plus: textOffset -%}
{%- assign mainTextY = mainTextCY | plus: mainFontSizeHalf -%}
{%- assign mainTextW = main_text | textWidth: mainTextFont -%}
{%- assign mainTextWHalf = mainTextW | divided_by: 2 -%}
{%- assign mainTextX = width | divided_by: 2 | minus: mainTextWHalf -%}

{%- assign boxW = mainTextW | plus: 300 -%}
{%- assign boxH = mainFontPx | plus: 200 -%}
{%- assign boxWHalf = boxW | divided_by: 2 -%}
{%- assign boxHHalf = boxH | divided_by: 2 -%}
{%- assign boxX = width | divided_by: 2 | minus: boxWHalf -%}
{%- assign boxYTextOffset = mainFontPx | times: 0.2 -%}
{%- assign boxY = mainTextCY | minus: boxHHalf | plus: boxYTextOffset -%}

{%- capture all_lower_text -%}
{{ lower_text | upcase | encodeForSVG }}{% if show_date == 'true' -%}
{%- if lower_text != '' %} | {% endif -%}
{{ date | formatDate: 'MMMM D, YYYY' | upcase }}{% endif -%}
{%- endcapture -%}

{%- assign lowerTextY = boxY | plus: boxH | plus: lowerHeight | plus: lowerFontPx -%}
{%- assign lowerTextW = all_lower_text | textWidth: lowerTextFont -%}
{%- assign lowerTextWHalf = lowerTextW | divided_by: 2 -%}
{%- assign lowerTextX = width | divided_by: 2 | minus: lowerTextWHalf -%}
                <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
                <defs>
                    <style type="text/css">
                        @font-face {
                            font-family: "Poppins";
                            src: url("${Poppins}");
                        }
                    </style>
                </defs>
                <image href="${RedBlueBG}" width="1920px" height="1080px" x="0" y="0" />
                <image href="" width="1920px" height="1080px" x="0" y="0" />
                <rect x="{{boxX}}" y="{{boxY}}" width="{{boxW}}" height="{{boxH}}"
                    style="stroke: white;stroke-width:5;fill:transparent;" />
                <text x="{{mainTextX}}" y="{{mainTextY}}" width="1300" height="600" fill="white"
                    style="font-size: {{mainFontPx}}px;font-weight: bold;font-family:{{fontFamily}};">{{ main_text | encodeForSVG }}</text>
                <text x="{{lowerTextX}}" y="{{lowerTextY}}" width="1300" height="600" fill="white"
                    style="font-size: {{lowerFontPx}}px;font-weight: 100;font-family:{{fontFamily}};">
                    {{all_lower_text | encodeForSVG}}
                </text>
                <image
                    id="logo"
                    href="${logo}"
                    style="display:inline;image-rendering:optimizeQuality"
                    preserveAspectRatio="none"
                    height="{{ logo_diameter }}"
                    width="{{ logo_diameter }}"
                    y="{{ height | minus: logo_diameter | minus: 50 }}"
                    x="{{ width | minus: logo_diameter | minus: 50 }}" />
        </svg>`,
};
