import React from "react";

import ListGroup from "react-bootstrap/ListGroup";

import { NavLink, useRouteMatch } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store";

const mapState = (state: RootState) => ({
    templates: state.graphics.templates,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

function TemplateChooser(props: PropsFromRedux) {
    let match = useRouteMatch();

    return (
        <div className="TemplateChooser">
            <ListGroup variant="flush">
                {props.templates.map((tpl) => {
                    return (
                        <ListGroup.Item key={tpl.slug}>
                            <NavLink to={`${match.url}/${tpl.slug}`}>
                                {tpl.title}
                            </NavLink>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </div>
    );
}

export default connector(TemplateChooser);
