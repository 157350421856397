import {
    FONT_LOADED,
    CHANGE_TEMPLATE,
    UPDATE_VARIABLE,
    GRAPHICS_UPLOAD_BEGIN,
    GRAPHICS_UPLOAD_SUCCESS,
    GRAPHICS_UPLOAD_FAILURE,
    CHANGE_MEDIASLOT,
    FONT_LOAD_FAILED,
} from "./types";

export const fontLoadFailed = () => ({
    type: FONT_LOAD_FAILED,
});

export const fontLoaded = (fontLoaded) => ({
    type: FONT_LOADED,
    fontLoaded: fontLoaded,
});

export const changeTemplate = (template) => (dispatch) => {
    dispatch({
        type: CHANGE_TEMPLATE,
        template: template,
    });
    if (template && template.loadFonts) {
        dispatch(fontLoaded(false));
        const fontsLoading = [];
        for (let fontName of Object.keys(template.loadFonts)) {
            const font = new FontFace(
                fontName,
                `url(${template.loadFonts[fontName]})`
            );
            fontsLoading.push(font.load());
        }
        Promise.all(fontsLoading)
            .then((fonts) => {
                for (let font of fonts) {
                    document.fonts.add(font);
                }
                dispatch(fontLoaded(true));
            })
            .catch(() => {
                dispatch(fontLoadFailed());
            });
    } else if (template) {
      dispatch(fontLoaded(true));
    }
};

export const changeMediaSlot = (mediaSlot) => ({
    type: CHANGE_MEDIASLOT,
    mediaSlot: mediaSlot,
});

export const updateVariable = (variable: string, value: any) => ({
    type: UPDATE_VARIABLE,
    variable: variable,
    value: value,
});

export const uploadBegin = () => ({
    type: GRAPHICS_UPLOAD_BEGIN,
});

export const uploadSuccess = (response) => ({
    type: GRAPHICS_UPLOAD_SUCCESS,
    mediaSlot: response.index + 1,
    name: response.name,
    description: response.description,
    hash: response.hash,
});

export const uploadFailure = (error) => ({
    type: GRAPHICS_UPLOAD_FAILURE,
    message: error,
});

export function upload(blob, slot, name) {
    return (dispatch) => {
        dispatch(uploadBegin());
        //   return fetch("/products")
        //     .then(handleErrors)
        //     .then(res => res.json())
        //     .then(json => {
        //       dispatch(uploadSuccess(json.products));
        //       return json.products;
        //     })
        //     .catch(error => dispatch(uploadFailure(error)));
        const url =
            (window.location.toString().includes("http://localhost")
                ? "https://studio.home.lbcde.org"
                : "") + "/api/uploadStill";
        var fd = new FormData();
        fd.append("file", blob);
        fd.append("index", Number(slot - 1).toFixed());
        fd.append("name", name);
        // fd.append("description", this.props.template.generated_name);
        return fetch(url, {
            method: "POST",
            body: fd,
            credentials: "include",
        })
            .then(handleErrors)
            .then((res) => res.json())
            .then((json) => {
                if (typeof json.error != undefined) {
                    throw json.errorType(json.error);
                }
                if (json.type === "TransferSuccess") {
                    dispatch(uploadSuccess(json));
                    return json;
                } else {
                    throw Error(json.type);
                }
            })
            .catch((error) => dispatch(uploadFailure(error)));
    };
}

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
