import {
    GraphicsTemplate,
    GraphicsVariableType,
} from "../../store/graphics/types";

export const SongInfoTemplate: GraphicsTemplate = {
    slug: "song_info",
    title: "Song Info",
    variables: {
        song: {
            name: "Song",
            value: "O to be Like Thee",
            type: GraphicsVariableType.Text,
        },
        info: {
            name: "Info",
            value:
                "Words by Thomas O. Chisholm; Music by William J. Kirkpatrick",
            type: GraphicsVariableType.Text,
        },
        copyright: {
            name: "Copyright",
            value: "2008",
            type: GraphicsVariableType.Text,
        },
        ccli_num: {
            name: "CCLI License #",
            value: "2349505",
            type: GraphicsVariableType.Number,
        },
    },
    name: `Song Info: {{ song }}`,
    background: false,
    template: `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
                <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
                <defs>
                    <style type="text/css">
                    @import url('https://fonts.googleapis.com/css?family=Arvo|Lato|Raleway|Text+Me+One&amp;display=block');
                    </style>
                </defs>
                <filter id="textDropShadow">
                    <feOffset in="SourceAlpha" dx="4" dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="8" result="DROP"></feGaussianBlur>
                    <feFlood flood-color="#000" result="COLOR"></feFlood>
                    <feComposite in="DROP" in2="COLOR" operator="in" result="SHADOW1"></feComposite>
                    <feComponentTransfer in="SHADOW1" result="SHADOW">
                        <feFuncA type="table" tableValues="0 0.75"></feFuncA>
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode in="SHADOW"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
                <text x="200" y="870" font-size="84px" fill="white" filter="url(#textDropShadow)" style="font-family:'Text Me One', sans-serif;">{{ song  | encodeForSVG}}</text>
                <text x="200" y="930" font-size="36px" fill="white" filter="url(#textDropShadow)"  style="font-family:'Text Me One', sans-serif;">{{ info | encodeForSVG }}</text>
                <text x="200" y="970" font-size="36px" fill="white" filter="url(#textDropShadow)"  style="font-family:'Text Me One', sans-serif;">&#169;{{ copyright | encodeForSVG }}</text>
                <text text-anchor="end" x="1720" y="970" font-size="28px" fill="white" filter="url(#textDropShadow)"  style="font-family:'Text Me One', sans-serif;">CCLI License No. {{ ccli_num | encodeForSVG }}</text>
        </svg>`,
};
