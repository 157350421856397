import React from "react";

import "./TemplateFiller.css";
import TemplateActions from "./TemplateActions";
import TemplateVariableForm from "./TemplateVariableForm";
import {
    map,
    isString,
    isDate,
    isFunction,
    isArray,
    mapObject,
} from "underscore";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { connect, ConnectedProps } from "react-redux";
import { upload, changeTemplate } from "../store/graphics/actions";
import { VideoModeSize } from "../store/atem/types";
import { RootState } from "../store";

import liquid from "./liquid";
import { Spinner, Alert } from "react-bootstrap";
import { GraphicsTemplate } from "../store/graphics/types";

const deepClone = function (obj) {
    return !obj || typeof obj !== "object"
        ? obj
        : isString(obj)
        ? String.prototype.slice.call(obj)
        : isDate(obj)
        ? new Date(obj.valueOf())
        : isFunction(obj.clone)
        ? obj.clone()
        : isArray(obj)
        ? map(obj, function (t) {
              return deepClone(t);
          })
        : mapObject(obj, function (val, key) {
              return deepClone(val);
          });
};

const mapState = (state: RootState) => ({
    templates: state.graphics.templates,
    template: state.graphics.template,
    fontLoaded: state.graphics.fontLoaded,
    fontLoadFailed: state.graphics.fontLoadFailed,
    mediaSlot: state.graphics.mediaSlot,
    variables: state.graphics.variables,
    isErrored: state.graphics.uploadFailed,
    errorMessage: state.graphics.uploadFailureMessage,
    videoMode: state.atem.videoMode,
});

const mapDispatch = (dispatch) => ({
    upload: (blob, slot, name) => dispatch(upload(blob, slot, name)),
    changeTemplate: (template) => dispatch(changeTemplate(template)),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type OwnState = {};

class TemplateFiller extends React.PureComponent<PropsFromRedux, OwnState> {
    constructor(props) {
        super(props);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);

        const slug = this.props.match.params.template;
        const tpl = this.props.templates.find((t) => t.slug === slug);
        this.props.changeTemplate(tpl);
    }

    componentDidUpdate(oldProps: PropsFromRedux) {
        const slug = this.props.match.params.template;
        const tpl = this.props.templates.find(
            (t: GraphicsTemplate) => t.slug === slug
        );
        if (tpl === undefined) {
            console.log(this.props);
            const newUrl = this.props.match.url.toString().replace(slug, "");
            this.props.history.push(newUrl);
            return;
        }
        if (
            oldProps.template === undefined ||
            tpl.slug !== oldProps.template.slug
        ) {
            this.props.changeTemplate(tpl);
        }
    }

    onUploadClick(e) {
        this.asBlob().then((blob) => {
            let variables = this.props.variables;
            if (variables === undefined) {
                variables = this.props.template.variables;
            }
            const variable_values = Object.fromEntries(
                Object.entries(variables).map((entry: [string, any]) => [
                    entry[0],
                    entry[1].value,
                ])
            );

            const name = liquid.parseAndRenderSync(
                this.props.template.name,
                variable_values
            );
            this.props.upload(blob, this.props.mediaSlot, name);
        });
    }

    onDownloadClick(e) {
        this.asBlob().then((blob) => {
            let variables = this.props.variables;
            if (variables === undefined) {
                variables = this.props.template.variables;
            }
            const variable_values = Object.fromEntries(
                Object.entries(variables).map((entry: [string, any]) => [
                    entry[0],
                    entry[1].value,
                ])
            );

            const name = liquid.parseAndRenderSync(
                this.props.template.name,
                variable_values
            );

            const objectURL = URL.createObjectURL(blob);
            const el = document.createElement("a");
            el.setAttribute("href", objectURL);
            el.setAttribute("download", `${name}.png`);
            el.style.display = "none";
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
        });
    }

    get SVGDataURL() {
        let variables = this.props.variables;
        if (variables === undefined) {
            variables = this.props.template.variables;
        }
        const variable_values = Object.fromEntries(
            Object.entries(variables).map((entry: [string, any]) => [
                entry[0],
                entry[1].value,
            ])
        );

        const svg_string = liquid.parseAndRenderSync(
            this.props.template.template,
            variable_values
        );
        return URL.createObjectURL(
            new Blob([svg_string], { type: "image/svg+xml" })
        );
        // return 'data:image/svg+xml;charset=utf-8;base64,' + btoa(svg_string);
    }

    asBlob(): Promise<Blob> {
        var img = new Image();
        var canvas = document.createElement("canvas");

        const current_mode = VideoModeSize[this.props.videoMode];

        canvas.width = current_mode.width;
        canvas.height = current_mode.height;

        img.src = this.SVGDataURL;

        return new Promise<Blob>((resolve, reject) => {
            img.onload = () => {
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(resolve, "image/png", 1.0);
            };
        }).finally(() => {
            img = null;
        });
    }

    render() {
        if (this.props.template === undefined) {
            return <h3>Select a template.</h3>;
        }
        return (
            <Container fluid className="TemplateFiller">
                <Col>
                    <h1>{this.props.template.title} Template</h1>
                    <Row>
                        <Col xs={12} lg={4} xl={3}>
                            <TemplateVariableForm />
                        </Col>
                        <Col xs={12} lg>
                            <div className="TemplatePreview">
                                {this.props.fontLoadFailed ? (
                                    <Alert variant="danger">
                                        A font failed to load! The template will
                                        fall back to another font.
                                    </Alert>
                                ) : (
                                    <></>
                                )}
                                <div className="img-overlay-wrap">
                                    <img
                                        alt="Background"
                                        src={
                                            this.props.template.background
                                                ? this.props.template.background
                                                : "https://home.lbcde.org/office_bg.png"
                                        }
                                    />
                                    <img
                                        id="overlay"
                                        className="overlay"
                                        alt="Preview of the overlay"
                                        src={this.SVGDataURL}
                                    />
                                    {this.props.fontLoaded ||
                                    this.props.fontLoadFailed ? (
                                        <></>
                                    ) : (
                                        <div
                                            className="overlay"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor:
                                                    "rgba(255,255,255,0.5)",
                                            }}
                                        >
                                            <h2>
                                                Loading font...{" "}
                                                <Spinner animation={"border"} />
                                            </h2>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <TemplateActions
                            onUploadClick={this.onUploadClick}
                            onDownloadClick={this.onDownloadClick}
                        />
                    </Row>
                </Col>
            </Container>
        );
    }
}

export default connector(TemplateFiller);
