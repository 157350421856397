export interface AuthState {
    authenticated: boolean;
    authFailed: boolean;
    authFailureMessage: string;
    isSignedIn: boolean;
    user: any;
}

export const AUTH_API_LOADED = "AUTH_API_LOADED";

export const AUTH_BEGIN = "AUTH_BEGIN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_SIGNED_OUT = "AUTH_SIGNED_OUT";

interface authAPILoadedAction {
    type: typeof AUTH_API_LOADED;
}

interface authBeginAction {
    type: typeof AUTH_BEGIN;
}

interface authSuccessAction {
    type: typeof AUTH_SUCCESS;
    user: string;
}

interface authFailureAction {
    type: typeof AUTH_FAILURE;
    message: string;
}

interface authSignedOutAction {
    type: typeof AUTH_SIGNED_OUT;
}

export type AuthActionTypes =
    | authAPILoadedAction
    | authBeginAction
    | authSuccessAction
    | authFailureAction
    | authSignedOutAction;
