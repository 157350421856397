export const getTextWidth = (text: string, font: string) => {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    context = null;
    canvas = null;
    return metrics.width;
};

export const escape_non_ascii = (string: string) => {
    const specialCharacters = ["&", '"', '"', "<", ">"];
    return string
        .split("")
        .map((character: string) => {
            if (
                character.charCodeAt(0) > 127 ||
                specialCharacters.includes(character)
            ) {
                return `&#${character.charCodeAt(0)};`;
            }
            return character;
        })
        .join("");
};

export const val = (variables, variable) => {
    return escape_non_ascii(variables[variable].value);
};
