import React from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { RootState } from "../store";

import { connect, ConnectedProps } from "react-redux";
import { changeMediaSlot } from "../store/graphics/actions";

const mapState = (state: RootState) => ({
    videoMode: state.atem.videoMode,
    templates: state.graphics.templates,
    template: state.graphics.template,
    mediaSlot: state.graphics.mediaSlot,
    variables: state.graphics.variables,
    isErrored: state.graphics.uploadFailed,
    errorMessage: state.graphics.uploadFailureMessage,
});

const mapDispatch = (dispatch) => ({
    changeMediaSlotAction: (slot) => dispatch(changeMediaSlot(slot)),
});

const connector = connect(mapState, mapDispatch);

interface OwnProps {
    onUploadClick: Function;
}

type PropsFromRedux = ConnectedProps<typeof connector> & OwnProps;

class TemplateActions extends React.PureComponent<PropsFromRedux> {
    render() {
        return (
            <div className="TemplateActions">
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>ATEM Media Slot</Form.Label>
                        <Form.Control
                            as="select"
                            value={this.props.mediaSlot}
                            onChange={(e) => {
                                this.props.changeMediaSlotAction(
                                    parseInt(e.currentTarget.value, 10)
                                );
                            }}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                <Button onClick={this.props.onUploadClick}>
                    Upload to ATEM
                </Button>{" "}
                <Button onClick={this.props.onDownloadClick}>Download</Button>
            </div>
        );
    }
}

export default connector(TemplateActions);
