import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TemplateChooser from "./graphics/TemplateChooser";
import TemplateFiller from "./graphics/TemplateFiller";

import { Switch, Route, useRouteMatch } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";

import { upload } from "./store/graphics/actions";
import { RootState } from "./store";

const mapState = (state: RootState) => ({
    template: state.graphics.template,
    mediaSlot: state.graphics.mediaSlot,
    variables: state.graphics.variables,
    isErrored: state.graphics.uploadFailed,
    errorMessage: state.graphics.uploadFailureMessage,
});

const mapDispatch = {
    upload: upload,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

function GraphicsDesigner(props: PropsFromRedux) {
    let match = useRouteMatch();

    return (
        <div className="GraphicsDesigner">
            <Container fluid>
                <Row>
                    <Col sm={4} lg={2}>
                        <TemplateChooser templates={props.templates} />
                    </Col>
                    <Col sm={8} lg={10}>
                        <Switch>
                            <Route
                                path={`${match.path}/:template`}
                                exact
                                component={TemplateFiller}
                            />
                            <Route path={match.path}>
                                <h3>Select a template.</h3>
                            </Route>
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default connector(GraphicsDesigner);
