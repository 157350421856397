export enum GraphicsVariableType {
    Text,
    Number,
    RGBAColor,
    DateTime,
    Bool,
}

export interface GraphicsVariables {
    [key: string]: {
        name: string;
        value: string;
        type: GraphicsVariableType;
    };
}

export interface GraphicsTemplate {
    slug: string;
    title: string;
    template: string;
    name: string;
    variables: GraphicsVariables;
    background: string | boolean;
    loadFonts?: {
        [key: string]: string;
    };
}

export interface GraphicsState {
    template: GraphicsTemplate;
    templates: Array<GraphicsTemplate>;
    mediaSlot: number;
    variables: GraphicsVariables;
    uploadFailed: boolean;
    uploadFailureMessage: string;
    fontLoaded: boolean;
    fontLoadFailed: boolean;
}

export const CHANGE_TEMPLATE = "CHANGE_TEMPLATE";
export const UPDATE_VARIABLE = "UPDATE_VARIABLE";
export const CHANGE_MEDIASLOT = "CHANGE_MEDIASLOT";
export const FONT_LOADED = "FONT_LOADED";
export const FONT_LOAD_FAILED = "FONT_LOAD_FAILED";

export const GRAPHICS_UPLOAD_BEGIN = "GRAPHICS_UPLOAD_BEGIN";
export const GRAPHICS_UPLOAD_SUCCESS = "GRAPHICS_UPLOAD_SUCCESS";
export const GRAPHICS_UPLOAD_FAILURE = "GRAPHICS_UPLOAD_FAILURE";

interface changeTemplateAction {
    type: typeof CHANGE_TEMPLATE;
    template: GraphicsTemplate;
}

interface changeMediaSlotAction {
    type: typeof CHANGE_MEDIASLOT;
    mediaSlot: number;
}

interface fontLoadedAction {
    type: typeof FONT_LOADED;
    fontLoaded: boolean;
}

interface fontLoadFailedAction {
    type: typeof FONT_LOAD_FAILED;
}

interface updateVariablesAction {
    type: typeof UPDATE_VARIABLE;
    variable: string;
    value: string;
}

interface uploadBeginAction {
    type: typeof GRAPHICS_UPLOAD_BEGIN;
    mediaSlot: number;
}

interface uploadSuccessAction {
    type: typeof GRAPHICS_UPLOAD_SUCCESS;
    mediaSlot: number;
    name: string;
    description: string;
    hash: string;
}

interface uploadFailureAction {
    type: typeof GRAPHICS_UPLOAD_FAILURE;
    message: string;
}

export type GraphicsActionTypes =
    | fontLoadedAction
    | fontLoadFailedAction
    | changeTemplateAction
    | changeMediaSlotAction
    | updateVariablesAction
    | uploadBeginAction
    | uploadFailureAction
    | uploadSuccessAction;
